<template>
  <div
    class="md:w-1/3 md:p-2 md:min-w-[350px] card-wrap"
    :data-match-commencing="match.commencing"
    v-click-outside="hideAllOverlays"
  >
    <div
      class="initial-card relative border border-black flex flex-col h-full bg-white"
    >
      <div class="top p-2 flex-1">
        <span class="text-gray-400 text-center w-full block text-sm title">
          {{ title }}
        </span>
        <div class="mt-2 flex items-center">
          <div class="w-1/4 text-center text-xs side-image">
            <div
              class="mx-auto w-10 h-10 mb-4 flex items-center justify-center"
            >
              <img :src="match.home_team.logo" />
            </div>
            {{ match.home_team.name }}
          </div>
          <div class="w-2/4 mx-4">
            <div class="flex w-full space-x-4 justify-center">
              <div class="text-xs flex-1 text-gray-400 text-center">
                home
                <span
                  class="block mt-2 text-base"
                  :class="{
                    ' text-green-400': highestPrediction(
                      match.home_team.prediction
                    ),
                  }"
                  >{{ Math.round(match.home_team.prediction) }}%</span
                >
              </div>
              <div class="text-xs flex-1 text-gray-400 text-center">
                draw
                <span
                  class="block mt-2 text-base"
                  :class="{
                    ' text-green-400': highestPrediction(match.draw.prediction),
                  }"
                  >{{ Math.round(match.draw.prediction) }}%</span
                >
              </div>
              <div class="text-xs flex-1 relative text-gray-400 text-center">
                away
                <span
                  class="block mt-2 text-base"
                  :class="{
                    ' text-green-400': highestPrediction(
                      match.away_team.prediction
                    ),
                  }"
                  >{{ Math.round(match.away_team.prediction) }}%</span
                >
              </div>
            </div>

            <div class="flex items-center justify-center mt-4">
              <button
                v-on:click="moreInfoToggle"
                class="text-blue-700 uppercase text-xs underline flex items-center justify-center more-info"
              >
                See Data
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 7l5 5m0 0l-5 5m5-5H6"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div class="w-1/4 text-center text-xs side-image">
            <div
              class="mx-auto w-10 h-10 mb-4 flex items-center justify-center"
            >
              <img :src="match.away_team.logo" />
            </div>
            {{ match.away_team.name }}
          </div>
        </div>
      </div>
      <div v-if="this.displayOdds" class="bottom p-2 bg-gray-200 flex">
        <div class="flex-1">
          <div class="flex text-sm">
            <span
              v-on:click="toggleItem('best_odds')"
              class="relative flex-1 text-center group underline"
            >
              <p>best_odds</p>

              <div
                :class="{ '!block': itemShowing['best_odds'] }"
                class="absolute left-1/2 p-2 border text-xs w-48 z-20 bottom-full transform text-black -translate-x-1/2 bg-white hidden group-hover:block w-1/4 ml-7"
              >
                <p class="overflow-hidden">
                  the current best odds available from high street bookmakers
                </p>
              </div>
            </span>

            <div class="flex w-2/3 items-center justify-center flex-shrink-0">
              <span
                v-on:click="toggleItem('home_team')"
                class="relative flex-1 text-center group underline"
                :class="{
                  ' text-green-400': compareOdds(
                    decimalToFraction(match.home_team.best_odds),
                    decimalToFraction(match.home_team.fair_odds)
                  ),
                }"
              >
                {{ convertOdds(match.home_team.best_odds) }}
                <div
                  :class="{ '!block': itemShowing['home_team'] }"
                  class="absolute left-1/2 p-2 border text-xs w-48 z-20 bottom-full transform text-black -translate-x-1/2 bg-white hidden group-hover:block"
                >
                  <div
                    v-if="isBetPromoAllowed"
                    class="flex items-center justify-center flex-col gap-2 mb-2 pt-1"
                  >
                    <img src="@/assets/betwinner.png" class="h-4" />
                    <a
                      href="https://bwredir.com/26le"
                      target="_blank"
                      class="text-blue-700 underline font-bold"
                      >200% Welcome Bonus with BetWinner Use Code: BETGET200</a
                    >
                  </div>
                  <span
                    v-for="(key, itemKey) in match.home_team.odds"
                    :key="`${itemKey}+home`"
                  >
                    <span v-if="key.bookie == match.home_team.bookmaker">
                      <span
                        v-if="
                          compareOdds(
                            decimalToFraction(key.odds),
                            decimalToFraction(match.home_team.fair_odds)
                          )
                        "
                        class="text-green-400 font-bold text-sm"
                      >
                        {{ key.bookie }} {{ convertOdds(key.odds) }}
                      </span>
                      <span v-else class="font-bold text-sm">
                        {{ key.bookie }} {{ convertOdds(key.odds) }}
                      </span>
                    </span>
                    <span v-else>
                      <span
                        v-if="
                          compareOdds(
                            decimalToFraction(key.odds),
                            decimalToFraction(match.home_team.fair_odds)
                          )
                        "
                        class="text-green-400"
                      >
                        {{ key.bookie }} {{ convertOdds(key.odds) }}
                      </span>
                      <span v-else>
                        {{ key.bookie }} {{ convertOdds(key.odds) }}
                      </span>
                    </span>
                    <br />
                  </span>
                </div>
              </span>
              <span
                v-on:click="toggleItem('draw')"
                class="relative flex-1 text-center group underline"
                :class="{
                  ' text-green-400': compareOdds(
                    decimalToFraction(match.draw.best_odds),
                    decimalToFraction(match.draw.fair_odds)
                  ),
                }"
              >
                {{ convertOdds(match.draw.best_odds) }}
                <div
                  :class="{ '!block': itemShowing['draw'] }"
                  class="absolute left-1/2 p-2 border text-xs w-48 z-20 bottom-full transform text-black -translate-x-1/2 bg-white hidden group-hover:block"
                >
                  <div
                    v-if="isBetPromoAllowed"
                    class="flex items-center justify-center flex-col gap-2 mb-2 pt-1"
                  >
                    <img src="@/assets/betwinner.png" class="h-4" />
                    <a
                      href="https://bwredir.com/26le"
                      target="_blank"
                      class="text-blue-700 underline font-bold"
                      >200% Welcome Bonus with BetWinner Use Code: BETGET200</a
                    >
                  </div>
                  <span
                    v-for="(key, itemKey) in match.draw.odds"
                    :key="`${itemKey}+draw`"
                  >
                    <span v-if="key.bookie == match.draw.bookmaker">
                      <span
                        v-if="
                          compareOdds(
                            decimalToFraction(key.odds),
                            decimalToFraction(match.draw.fair_odds)
                          )
                        "
                        class="text-green-400 font-bold text-sm"
                      >
                        {{ key.bookie }} {{ convertOdds(key.odds) }}
                      </span>
                      <span v-else class="font-bold text-sm">
                        {{ key.bookie }} {{ convertOdds(key.odds) }}
                      </span>
                    </span>
                    <span v-else>
                      <span
                        v-if="
                          compareOdds(
                            decimalToFraction(key.odds),
                            decimalToFraction(match.draw.fair_odds)
                          )
                        "
                        class="text-green-400"
                      >
                        {{ key.bookie }} {{ convertOdds(key.odds) }}
                      </span>
                      <span v-else>
                        {{ key.bookie }} {{ convertOdds(key.odds) }}
                      </span>
                    </span>
                    <br />
                  </span>
                </div>
              </span>
              <span
                v-on:click="toggleItem('away_team')"
                class="relative flex-1 text-center group underline"
                :class="{
                  ' text-green-400': compareOdds(
                    decimalToFraction(match.away_team.best_odds),
                    decimalToFraction(match.away_team.fair_odds)
                  ),
                }"
              >
                {{ convertOdds(match.away_team.best_odds) }}
                <div
                  :class="{ '!block': itemShowing['away_team'] }"
                  class="absolute left-1/2 p-2 border text-xs w-48 z-20 bottom-full transform text-black -translate-x-1/2 bg-white hidden group-hover:block show-on-blur"
                >
                  <div
                    v-if="isBetPromoAllowed"
                    class="flex items-center justify-center flex-col gap-2 mb-2 pt-1"
                  >
                    <img src="@/assets/betwinner.png" class="h-4" />
                    <a
                      href="https://bwredir.com/26le"
                      target="_blank"
                      class="text-blue-700 underline font-bold"
                      >200% Welcome Bonus with BetWinner Use Code: BETGET200</a
                    >
                  </div>
                  <span
                    v-for="(key, itemKey) in match.away_team.odds"
                    :key="`${itemKey}+away`"
                  >
                    <span v-if="key.bookie == match.away_team.bookmaker">
                      <span
                        v-if="
                          compareOdds(
                            decimalToFraction(key.odds),
                            decimalToFraction(match.away_team.fair_odds)
                          )
                        "
                        class="text-green-400 font-bold text-sm"
                      >
                        {{ key.bookie }} {{ convertOdds(key.odds) }}
                      </span>
                      <span v-else class="font-bold text-sm">
                        {{ key.bookie }} {{ convertOdds(key.odds) }}
                      </span>
                    </span>
                    <span v-else>
                      <span
                        v-if="
                          compareOdds(
                            decimalToFraction(key.odds),
                            decimalToFraction(match.away_team.fair_odds)
                          )
                        "
                        class="text-green-400"
                      >
                        {{ key.bookie }} {{ convertOdds(key.odds) }}
                      </span>
                      <span v-else>
                        {{ key.bookie }} {{ convertOdds(key.odds) }}
                      </span>
                    </span>
                    <br />
                  </span>
                </div>
              </span>
            </div>
          </div>
          <div class="flex text-sm">
            <span
              v-on:click="toggleItem('fair_odds')"
              class="relative flex-1 text-center group underline"
            >
              <p>fair_odds</p>

              <div
                :class="{ '!block': itemShowing['fair_odds'] }"
                class="absolute left-1/2 p-2 border text-xs w-48 z-20 bottom-full transform text-black -translate-x-1/2 bg-white hidden group-hover:block w-1/4 ml-7"
              >
                <p class="overflow-hidden">
                  the true probability of the result, calculated using machine
                  learning
                </p>
              </div>
            </span>

            <div class="flex w-2/3 items-center justify-center flex-shrink-0">
              <p class="flex-1 text-center">
                {{ convertOdds(match.home_team.fair_odds) }}
              </p>
              <p class="flex-1 text-center">
                {{ convertOdds(match.draw.fair_odds) }}
              </p>
              <p class="flex-1 text-center">
                {{ convertOdds(match.away_team.fair_odds) }}
              </p>
            </div>

            <!-- <div class="w-1/4 text-center relative"> -->
            <!-- <div class="group inline-block"> -->
            <!-- <svg
                  v-on:click="toggleItem('info')"
                  xmlns="http://www.w3.org/2000/svg"
                  class="mx-auto h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <ul
                  :class="{ '!block': itemShowing['info'] }"
                  class="absolute left-1/2 p-2 list-inside list-disc border text-left text-xs w-48 z-20 bottom-full transform -translate-x-1/2 bg-white hidden group-hover:block"
                  v-if="match.predictions"
                >
                  <li>
                    {{ niceName("btts") }}:
                    {{ Math.round(match.predictions.btts) }}%
                  </li>
                  <li>
                    {{ niceName("HT_over_0_5") }}:
                    {{ Math.round(match.predictions.HT_over_0_5) }}%
                  </li>
                  <li>
                    {{ niceName("HT_over_1_5") }}:
                    {{ Math.round(match.predictions.HT_over_1_5) }}%
                  </li>
                  <li>
                    {{ niceName("AT_over_0_5") }}:
                    {{ Math.round(match.predictions.AT_over_0_5) }}%
                  </li>
                  <li>
                    {{ niceName("AT_over_1_5") }}:
                    {{ Math.round(match.predictions.AT_over_1_5) }}%
                  </li>
                  <li>
                    {{ niceName("FT_over_2_5") }}:
                    {{ Math.round(match.predictions.FT_over_2_5) }}%
                  </li>
                  <li>
                    {{ niceName("FT_over_3_5") }}:
                    {{ Math.round(match.predictions.FT_over_3_5) }}%
                  </li>
                </ul> -->
            <!-- </div> -->
            <!-- </div> -->
          </div>
        </div>

        <div class="w-3/12 text-center relative">
          <div
            class="group flex items-center justify-center h-full"
            v-on:click="toggleItem('tv')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 mx-auto pointer-events-none"
              viewBox="0 0 20 20"
              fill="currentColor"
              :class="{ 'opacity-20': !match.tvstations.length }"
            >
              <path
                fill-rule="evenodd"
                d="M3 5a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-2 2h-2.22l.123.489.804.804A1 1 0 0113 18H7a1 1 0 01-.707-1.707l.804-.804L7.22 15H5a2 2 0 01-2-2V5zm5.771 7H5V5h10v7H8.771z"
                clip-rule="evenodd"
              />
            </svg>
            <ul
              v-if="match.tvstations.length"
              class="absolute left-1/2 p-2 list-inside list-disc border text-left text-xs w-48 z-20 bottom-full transform -translate-x-1/2 bg-white hidden group-hover:block"
            >
              <li
                v-for="(station, key) in match.tvstations"
                :key="`${key}+tvstations`"
              >
                {{ station }}
              </li>
            </ul>
            <ul
              v-else
              class="absolute left-1/2 p-2 list-inside list-disc border text-left text-xs w-48 z-20 bottom-full transform -translate-x-1/2 bg-white hidden group-hover:block"
            >
              <li>Not televised.</li>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
      ></div>
    </div>
    <portal to="overlay">
      <div
        class="fixed inset-0 z-40 p-4 overflow-scroll"
        v-if="popoverShowing"
        v-on:click="itemShowing['info'] = false"
      >
        <div
          class="fixed inset-0 bg-white/50"
          v-on:click.self="moreInfoToggle"
        ></div>
        <div
          class="bg-white border-black relative border p-4 pb-0 w-full mx-auto max-w-2xl"
        >
          <svg
            v-on:click="moreInfoToggle"
            class="cursor-pointer absolute right-3 top-3 h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>

          <template v-if="gotOverlayData">
            <div class="top flex-1 mb-4">
              <span class="text-gray-400 text-center w-full block text-sm">{{
                title
              }}</span>
              <div class="mt-2 flex items-center">
                <div class="w-1/4 text-center text-xs">
                  <div
                    class="mx-auto w-10 h-10 mb-4 flex items-center justify-center"
                  >
                    <img :src="match.home_team.logo" />
                  </div>
                  {{ match.home_team.name }}
                  <p
                    class="text-center text-sm text-gray-400 mt-4"
                    v-if="popOverData.home_team.league_position != 'N/A'"
                  >
                    {{ addNth(popOverData.home_team.league_position) }}
                  </p>
                  <div
                    class="flex items-center flex-row-reverse justify-center mt-2 gap-0.5"
                  >
                    <span
                      v-for="(item, key) in recentForm('home_team').slice(0, 5)"
                      :key="key"
                      class="w-3 h-3 flex items-center justify-center text-white rounded-full"
                      :class="{
                        'bg-red-600': item == 'L',
                        'bg-gray-600 ': item == 'D',
                        'bg-green-600': item == 'W',
                      }"
                    >
                      <svg
                        v-if="item == 'W'"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-2 w-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>

                      <svg
                        v-if="item == 'L'"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-2 w-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>

                      <span
                        v-if="item == 'D'"
                        class="bg-white h-0.5 w-1.5 rounded-full"
                      ></span>
                    </span>
                  </div>
                </div>
                <div class="w-2/4 px-4">
                  <div class="flex mx-4 space-x-4 justify-center">
                    <div class="text-xs flex-1 text-gray-400 text-center">
                      home
                      <span
                        class="block mt-2 text-base"
                        :class="{
                          ' text-green-400': highestPrediction(
                            match.home_team.prediction
                          ),
                        }"
                        >{{ Math.round(match.home_team.prediction) }}%</span
                      >
                    </div>
                    <div class="text-xs flex-1 text-gray-400 text-center">
                      draw
                      <span
                        class="block mt-2 text-base"
                        :class="{
                          ' text-green-400': highestPrediction(
                            match.draw.prediction
                          ),
                        }"
                        >{{ Math.round(match.draw.prediction) }}%</span
                      >
                    </div>
                    <div
                      class="text-xs flex-1 relative text-gray-400 text-center"
                    >
                      away
                      <span
                        class="block mt-2 text-base"
                        :class="{
                          ' text-green-400': highestPrediction(
                            match.away_team.prediction
                          ),
                        }"
                        >{{ Math.round(match.away_team.prediction) }}%</span
                      >
                    </div>
                  </div>
                  <p class="uppercase text-center text-sm text-gray-500 mt-4">
                    {{ popOverData.competition }}
                  </p>
                  <p class="uppercase text-center text-xs text-gray-400 mt-2">
                    {{ getStage(match, popOverData) }}
                  </p>
                </div>
                <div class="w-1/4 text-center text-xs">
                  <div
                    class="mx-auto w-10 h-10 mb-4 flex items-center justify-center"
                  >
                    <img :src="match.away_team.logo" />
                  </div>
                  {{ match.away_team.name }}
                  <p
                    class="text-center text-sm text-gray-400 mt-4"
                    v-if="popOverData.away_team.league_position != 'N/A'"
                  >
                    {{ addNth(popOverData.away_team.league_position) }}
                  </p>
                  <div
                    class="flex items-center flex-row-reverse justify-center mt-2 gap-0.5"
                  >
                    <span
                      v-for="(item, key) in recentForm('away_team').slice(0, 5)"
                      :key="key"
                      class="w-3 h-3 flex items-center justify-center text-white rounded-full"
                      :class="{
                        'bg-red-600': item == 'L',
                        'bg-gray-600 ': item == 'D',
                        'bg-green-600': item == 'W',
                      }"
                    >
                      <svg
                        v-if="item == 'W'"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-2 w-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>

                      <svg
                        v-if="item == 'L'"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-2 w-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>

                      <span
                        v-if="item == 'D'"
                        class="bg-white h-0.5 w-1.5 rounded-full"
                      ></span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <CardOverlayItem title="Match Summary">
              <div
                class="space-y-4 mt-3 px-6 flex flex-col md:w-[85%] mx-auto"
                v-if="
                  'summary' in popOverData && 'insights' in popOverData.summary && 'home_team' in popOverData.summary.insights && 'form' in popOverData.summary.insights.home_team
                " 
              >
              <h3 class="text-gray-500 uppercase text-center text-xs font-bold">
                  Match
                </h3>
                <span v-if="'news' in popOverData.summary.insights.match" class="text-xs">{{ popOverData.summary.insights.match.news }}</span>
                <ul v-else>
                  <li><span class="text-xs">&#8226;<span v-for="item in popOverData.summary.insights.match.headtohead"><strong v-if="popOverData.summary.insights.match.bold.includes(item)">{{ item }}</strong><span v-else>{{ item }}</span></span><br></span></li>
                  <li v-if="popOverData.summary.insights.match.homeheadtohead && popOverData.summary.insights.match.homeheadtohead.length > 0"><span class="text-xs">&#8226;<span v-for="item in popOverData.summary.insights.match.homeheadtohead"><strong v-if="popOverData.summary.insights.match.bold.includes(item)">{{ item }}</strong><span v-else>{{ item }}</span></span><br></span></li>
                </ul>
                <h3 class="text-gray-500 uppercase text-center text-xs font-bold">
                  {{ popOverData.home_team.name }}
                </h3>
                <span v-if="'news' in popOverData.summary.insights.home_team && popOverData.summary.insights.home_team.news.length > 0" class="text-xs">{{ popOverData.summary.insights.home_team.news }}</span>
                <ul v-else>
                  <li><span class="text-xs">&#8226;<span v-for="item in popOverData.summary.insights.home_team.form"><strong v-if="popOverData.summary.insights.home_team.bold.includes(item)">{{ item }}</strong><span v-else>{{ item }}</span></span><br></span></li>
                  <li><span class="text-xs">&#8226;<span v-for="item in popOverData.summary.insights.home_team.attackdefence"><strong v-if="popOverData.summary.insights.home_team.bold.includes(item)">{{ item }}</strong><span v-else>{{ item }}</span></span><br></span></li>
                  <li><span class="text-xs">&#8226;<span v-for="item in popOverData.summary.insights.home_team.goalscorer"><strong v-if="popOverData.summary.insights.home_team.bold.includes(item)">{{ item }}</strong><span v-else>{{ item }}</span></span><br></span></li>
                </ul>
                <h3 class="text-gray-500 uppercase text-center text-xs font-bold">
                  {{ popOverData.away_team.name }}
                </h3>
                <span v-if="'news' in popOverData.summary.insights.away_team && popOverData.summary.insights.away_team.news.length > 0" class="text-xs">{{ popOverData.summary.insights.away_team.news }}</span>
                <ul v-else>
                  <li><span class="text-xs">&#8226;<span v-for="item in popOverData.summary.insights.away_team.form"><strong v-if="popOverData.summary.insights.away_team.bold.includes(item)">{{ item }}</strong><span v-else>{{ item }}</span></span><br></span></li>
                  <li><span class="text-xs">&#8226;<span v-for="item in popOverData.summary.insights.away_team.attackdefence"><strong v-if="popOverData.summary.insights.away_team.bold.includes(item)">{{ item }}</strong><span v-else>{{ item }}</span></span><br></span></li>
                  <li><span class="text-xs">&#8226;<span v-for="item in popOverData.summary.insights.away_team.goalscorer"><strong v-if="popOverData.summary.insights.away_team.bold.includes(item)">{{ item }}</strong><span v-else>{{ item }}</span></span><br></span></li>
                </ul>
              </div>
              <div v-else class="flex items-start align-top space-x-4">
                <span
                  class="w-full align-bottom py-2 text-center text-gray-500 text-xs"
                >
                  No Match Summary found.
                </span>
              </div>
          </CardOverlayItem>
            <CardOverlayItem title="Head to head">
              <div
                class="space-y-4 mt-3"
                v-if="
                  popOverData.head_to_head &&
                  popOverData.head_to_head.length > 0
                "
              >
                <div
                  class="text-xs border-2 flex items-center border-gray-300 p-2 shadow"
                  v-for="(item, key) in sliceItemLength('head_to_head')"
                  :key="key"
                >
                  <MatchEventListCard :matchEventDetails="item" />
                </div>
                <div class="flex items-center justify-center mt-4">
                  <button
                    v-on:click="toggleSliceItem('head_to_head')"
                    class="text-gray-400 underline text-xxs"
                  >
                    Show
                    <template v-if="sliceList.head_to_head <= 10">more</template
                    ><template v-else>less</template>
                  </button>
                </div>
              </div>
              <div v-else class="flex items-start align-top space-x-4">
                <span
                  class="w-full align-bottom py-2 text-center text-gray-500 text-xs"
                >
                  No head to head matches found.
                </span>
              </div>
            </CardOverlayItem>

            <CardOverlayItem title="Recent Form">
              <div
                class="space-y-4 mt-3"
                v-if="
                  popOverData.home_team.recent_matches &&
                  popOverData.home_team.recent_matches.length > 0
                "
              >
                <h3
                  class="text-gray-500 uppercase text-center text-xs font-bold"
                >
                  {{ popOverData.home_team.name }}
                </h3>
                <div class="space-y-4 mt-3">
                  <div
                    class="text-xs border-2 flex items-center border-gray-300 p-2 shadow"
                    v-for="(item, key) in sliceItemLength(
                      'home_team',
                      'recent_matches'
                    )"
                    :class="
                      colorOfMatch(
                        recentForm('home_team'),
                        key,
                        popOverData.competition
                      )
                    "
                    :key="key"
                  >
                    <MatchEventListCard :matchEventDetails="item" />
                  </div>
                  <div class="flex items-center justify-center mt-4">
                    <button
                      v-on:click="toggleSliceItem('home_team')"
                      class="text-gray-400 underline text-xxs"
                    >
                      Show
                      <template v-if="sliceList.home_team <= 10">more</template
                      ><template v-else>less</template>
                    </button>
                  </div>
                </div>
              </div>
              <div v-else class="flex items-start align-top space-x-4">
                <span
                  class="w-full align-bottom py-2 text-center text-gray-500 text-xs"
                >
                  No recent home team matches found in {{ match.competition }}
                </span>
              </div>

              <div
                class="space-y-4 mt-3"
                v-if="
                  popOverData.away_team.recent_matches &&
                  popOverData.away_team.recent_matches.length > 0
                "
              >
                <h3
                  class="text-gray-500 uppercase text-center text-xs font-bold"
                >
                  {{ popOverData.away_team.name }}
                </h3>
                <div class="space-y-4 mt-3">
                  <div
                    class="text-xs border-2 flex items-center border-gray-300 p-2 shadow"
                    v-for="(item, key) in sliceItemLength(
                      'away_team',
                      'recent_matches'
                    )"
                    :class="
                      colorOfMatch(
                        recentForm('away_team'),
                        key,
                        popOverData.competition
                      )
                    "
                    :key="key"
                  >
                    <MatchEventListCard :matchEventDetails="item" />
                  </div>
                  <div class="flex items-center justify-center mt-4">
                    <button
                      v-on:click="toggleSliceItem('away_team')"
                      class="text-gray-400 underline text-xxs"
                    >
                      Show
                      <template v-if="sliceList.away_team <= 10">more</template
                      ><template v-else>less</template>
                    </button>
                  </div>
                </div>
              </div>
              <div v-else class="flex items-start align-top space-x-4">
                <span
                  class="w-full align-bottom py-2 text-center text-gray-500 text-xs"
                >
                  No recent away team matches found in {{ match.competition }}
                </span>
              </div>
            </CardOverlayItem>

            <CardOverlayItem
              title="Recent Cup Form"
              v-if="
                (popOverData.home_team.recent_cup_matches &&
                  popOverData.home_team.recent_cup_matches.length > 0) ||
                (popOverData.away_team.recent_cup_matches &&
                  popOverData.away_team.recent_cup_matches.length > 0)
              "
            >
              <div
                class="space-y-4 mt-3"
                v-if="
                  popOverData.home_team.recent_cup_matches &&
                  popOverData.home_team.recent_cup_matches.length > 0
                "
              >
                <h3
                  class="text-gray-500 uppercase text-center text-xs font-bold"
                >
                  {{ popOverData.home_team.name }}
                </h3>
                <div class="space-y-4 mt-3">
                  <div
                    class="text-xs border-2 flex items-center border-gray-300 p-2 shadow"
                    v-for="(item, key) in sliceItemLength(
                      'home_team',
                      'recent_cup_matches'
                    )"
                    :class="
                      colorOfMatch(
                        recentCupForm('home_team'),
                        key,
                        popOverData.competition
                      )
                    "
                    :key="key"
                  >
                    <MatchEventListCard :matchEventDetails="item" />
                  </div>
                  <!-- <div class="flex items-center justify-center mt-4">
                    <button
                      v-on:click="toggleSliceItem('home_team_cup')"
                      class="text-gray-400 underline text-xxs"
                    >
                      Show
                      <template v-if="sliceList.home_team_cup <= 10"
                        >more</template
                      ><template v-else>less</template>
                    </button>
                  </div> -->
                </div>
                <!-- <div
                  :class="
                    colorOfMatch(
                      recentCupForm('home_team'),
                      key,
                      popOverData.competition
                    )
                  "
                  class="text-xs border-2 flex items-center border-gray-300 p-2"
                  v-for="(item, key) in popOverData.home_team
                    .recent_cup_matches"
                  :key="key"
                >
                  <div class="flex-1">
                    <p>{{ item.home_team.name }}</p>
                    <p>{{ item.away_team.name }}</p>
                  </div>
                  <div class="flex items-center space-x-6">
                    <div>
                      <p>{{ item.result.split("-")[0] }}</p>
                      <p>{{ item.result.split("-")[1] }}</p>
                    </div>
                    <div class="text-center">
                      <small class="text-xxxs -mt-1 block text-gray-400">{{
                        item.date
                      }}</small>
                      <img
                        :src="item.competition_logo"
                        :alt="item.competition"
                        :title="item.competition"
                        class="w-6 mx-auto mt-1"
                      />
                    </div>
                  </div>
                </div> -->
              </div>
              <div v-else class="flex items-start align-top space-x-4">
                <span
                  class="w-full align-bottom py-2 text-center text-gray-500 text-xs"
                >
                  No recent matches found in {{ match.competition }}
                </span>
              </div>
              <div
                class="space-y-4 mt-3"
                v-if="
                  popOverData.away_team.recent_cup_matches &&
                  popOverData.away_team.recent_cup_matches.length > 0
                "
              >
                <h3
                  class="text-gray-500 uppercase text-center text-xs font-bold"
                >
                  {{ popOverData.away_team.name }}
                </h3>
                <div class="space-y-4 mt-3">
                  <div
                    class="text-xs border-2 flex items-center border-gray-300 p-2 shadow"
                    v-for="(item, key) in sliceItemLength(
                      'away_team',
                      'recent_cup_matches'
                    )"
                    :class="
                      colorOfMatch(
                        recentCupForm('away_team'),
                        key,
                        popOverData.competition
                      )
                    "
                    :key="key"
                  >
                    <MatchEventListCard :matchEventDetails="item" />
                  </div>
                  <!-- <div class="flex items-center justify-center mt-4">
                    <button
                      v-on:click="toggleSliceItem('away_team_cup')"
                      class="text-gray-400 underline text-xxs"
                    >
                      Show
                      <template v-if="sliceList.away_team_cup <= 10"
                        >more</template
                      ><template v-else>less</template>
                    </button>
                  </div> -->
                </div>
                <!-- <div
                  :class="colorOfMatch(recentCupForm('away_team'), key)"
                  class="text-xs border-2 flex items-center border-gray-300 p-2"
                  v-for="(item, key) in popOverData.away_team
                    .recent_cup_matches"
                  :key="key"
                >
                  <div class="flex-1">
                    <p>{{ item.home_team.name }}</p>
                    <p>{{ item.away_team.name }}</p>
                  </div>
                  <div class="flex items-center space-x-6">
                    <div>
                      <p>{{ item.result.split("-")[0] }}</p>
                      <p>{{ item.result.split("-")[1] }}</p>
                    </div>
                    <div class="text-center">
                      <small class="text-xxxs -mt-1 block text-gray-400">{{
                        item.date
                      }}</small>
                      <img
                        :src="item.competition_logo"
                        :alt="item.competition"
                        :title="item.competition"
                        class="w-6 mx-auto mt-1"
                      />
                    </div>
                  </div>
                </div> -->
              </div>
              <div v-else class="flex items-start align-top space-x-4">
                <span
                  class="w-full align-bottom py-2 text-center text-gray-500 text-xs"
                >
                  No recent matches found in {{ match.competition }}
                </span>
              </div>
            </CardOverlayItem>

            <CardOverlayItem title="Team Stats">
              <h3 class="text-gray-500 text-center text-xs font-bold p-1.5 pt-3">
                All Competitions 
                <button
                  v-on:click="toggleTimeFrameDropdown('teamstats')"
                  class="text-blue-700 font-bold underline"
                >
                  {{ getTimeFrameDropdown('teamstats') }}<svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-3 text-blue-700 inline-block pb-1"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="3"
                      viewBox="0 0 24 24"
                      :class="{ 'rotate-180': open }"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19 9l-7 7-7-7"
                      />
                  </svg>
                </button>
              </h3> 
              <div class="w-1/2" v-if="toggleTimeFrameDropdowns.teamstats">
                <div
                  class="bg-white w-16 absolute z-10 border border-gray-400 ml-[50%]"
                >
                <div>
                  <button
                    v-on:click="setTimeFrameDropdown('teamstats', 'season')"
                    class="text-xs font-bold border-t-0 p-1 bg-white text-blue-700 flex items-center justify-center"
                  >
                    season&nbsp; 
                  </button>
                </div>
                <div class="border-gray-200 border-t-2">
                  <button
                    v-on:click="setTimeFrameDropdown('teamstats', 'last_5')"
                    class="text-xs font-bold p-1 bg-white text-blue-700 flex items-center justify-center"
                  >
                    last 5&nbsp;
                  </button>
                </div>
                <div class="border-gray-200 border-t-2">
                  <button
                    v-on:click="setTimeFrameDropdown('teamstats', 'last_10')"
                    class="text-xs font-bold p-1 bg-white text-blue-700 flex items-center justify-center"
                  >
                    last 10
                  </button>
                </div>
              </div>
              </div>
              <table class="w-full text-center text-xxs flex flex-col">
                <tr class="flex">
                  <td class="p-2 w-1/4">
                    <h3 class="text-gray-500 uppercase text-xs font-bold">
                      {{ popOverData.home_team.name }}
                    </h3>
                  </td>
                  <td class="p-2 flex-1"></td>
                  <td class="p-2 w-1/4">
                    <h3 class="text-gray-500 uppercase text-xs font-bold">
                      {{ popOverData.away_team.name }}
                    </h3>
                  </td>
                </tr>
                <tr class="flex">
                  <div
                    class="flex items-center flex-row-reverse justify-center mt-2 gap-0.5"
                  >
                    <span
                      v-for="(item, key) in recentHomeForm('home_team').slice(
                        0,
                        5
                      )"
                      :key="key"
                      class="w-3 h-3 flex items-center justify-center text-white rounded-full"
                      :class="{
                        'bg-red-600': item == 'L',
                        'bg-gray-600 ': item == 'D',
                        'bg-green-600': item == 'W',
                      }"
                    >
                      <svg
                        v-if="item == 'W'"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-2 w-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>

                      <svg
                        v-if="item == 'L'"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-2 w-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>

                      <span
                        v-if="item == 'D'"
                        class="bg-white h-0.5 w-1.5 rounded-full"
                      ></span>
                    </span>
                  </div>
                  <td class="p-2 flex-1">Home Form</td>
                  <div
                    class="flex items-center flex-row-reverse justify-center mt-2 gap-0.5"
                  >
                    <span
                      v-for="(item, key) in recentHomeForm('away_team').slice(
                        0,
                        5
                      )"
                      :key="key"
                      class="w-3 h-3 flex items-center justify-center text-white rounded-full"
                      :class="{
                        'bg-red-600': item == 'L',
                        'bg-gray-600 ': item == 'D',
                        'bg-green-600': item == 'W',
                      }"
                    >
                      <svg
                        v-if="item == 'W'"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-2 w-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>

                      <svg
                        v-if="item == 'L'"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-2 w-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>

                      <span
                        v-if="item == 'D'"
                        class="bg-white h-0.5 w-1.5 rounded-full"
                      ></span>
                    </span>
                  </div>
                </tr>
                <tr class="flex">
                  <div
                    class="flex items-center flex-row-reverse justify-center mt-2 gap-0.5"
                  >
                    <span
                      v-for="(item, key) in recentAwayForm('home_team').slice(
                        0,
                        5
                      )"
                      :key="key"
                      class="w-3 h-3 flex items-center justify-center text-white rounded-full"
                      :class="{
                        'bg-red-600': item == 'L',
                        'bg-gray-600 ': item == 'D',
                        'bg-green-600': item == 'W',
                      }"
                    >
                      <svg
                        v-if="item == 'W'"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-2 w-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>

                      <svg
                        v-if="item == 'L'"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-2 w-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>

                      <span
                        v-if="item == 'D'"
                        class="bg-white h-0.5 w-1.5 rounded-full"
                      ></span>
                    </span>
                  </div>
                  <td class="p-2 flex-1">Away Form</td>
                  <div
                    class="flex items-center flex-row-reverse justify-center mt-2 gap-0.5"
                  >
                    <span
                      v-for="(item, key) in recentAwayForm('away_team').slice(
                        0,
                        5
                      )"
                      :key="key"
                      class="w-3 h-3 flex items-center justify-center text-white rounded-full"
                      :class="{
                        'bg-red-600': item == 'L',
                        'bg-gray-600 ': item == 'D',
                        'bg-green-600': item == 'W',
                      }"
                    >
                      <svg
                        v-if="item == 'W'"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-2 w-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>

                      <svg
                        v-if="item == 'L'"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-2 w-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>

                      <span
                        v-if="item == 'D'"
                        class="bg-white h-0.5 w-1.5 rounded-full"
                      ></span>
                    </span>
                  </div>
                </tr>
                <tr
                  class="relative overflow-hidden bg-white flex"
                  v-if="
                    popOverData.home_team.stats[getTimeFrameDropdown('teamstats')] &&
                    popOverData.away_team.stats[getTimeFrameDropdown('teamstats')]
                  "
                >
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].pct_clean_sheets
                        ? popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].pct_clean_sheets
                        : ""
                    }}
                  </td>
                  <td :class="`relative z-10 p-2 flex-1`">Clean Sheets</td>
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].pct_clean_sheets
                        ? popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].pct_clean_sheets
                        : ""
                    }}
                  </td>
                  <div
                    v-if="
                      popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].pct_clean_sheets &&
                      popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].pct_clean_sheets
                    "
                    class="absolute inset-1.5 inset-x-[5%] flex"
                  >
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].pct_clean_sheets,
                            popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].pct_clean_sheets
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].pct_clean_sheets,
                            popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].pct_clean_sheets
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </tr>
                <tr class="relative overflow-hidden bg-white flex">
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].failed_to_score.total
                        ? popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].failed_to_score.total
                        : ""
                    }}
                  </td>
                  <td :class="`relative z-10 p-2 flex-1`">Failed to Score</td>
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].failed_to_score.total
                        ? popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].failed_to_score.total
                        : ""
                    }}
                  </td>
                  <div
                    v-if="
                      popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].failed_to_score &&
                      popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].failed_to_score
                    "
                    class="absolute inset-1.5 inset-x-[5%] flex"
                  >
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].failed_to_score,
                            popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].failed_to_score
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].failed_to_score,
                            popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].failed_to_score
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </tr>
                <tr class="relative overflow-hidden bg-white flex">
                  <td :class="`w-1/4 relative z-10 p-2`">
                    {{
                      popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].average_goals.F
                        ? popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].average_goals.F.total
                        : 0
                    }}
                  </td>
                  <td :class="`flex-1 relative z-10 p-2`">Avg Goals For</td>
                  <td :class="`w-1/4 relative z-10 p-2`">
                    {{
                      popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].average_goals.F
                        ? popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].average_goals.F.total
                        : 0
                    }}
                  </td>
                  <div
                    class="absolute inset-1.5 inset-x-[5%] flex"
                    v-if="
                      popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].average_goals_for &&
                      popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].average_goals_for
                    "
                  >
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')]
                              .average_goals_for,
                            popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].average_goals_for
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')]
                              .average_goals_for,
                            popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].average_goals_for
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </tr>
                <tr class="relative overflow-hidden bg-white flex">
                  <td :class="`w-1/4 relative z-10 p-2`">
                    {{
                      popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].average_goals.A
                        ? popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].average_goals.A.total
                        : 0
                    }}
                  </td>
                  <td :class="`flex-1 relative z-10 p-2`">Avg Goals Against</td>
                  <td :class="`w-1/4 relative z-10 p-2`">
                    {{
                      popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].average_goals.A
                        ? popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].average_goals.A.total
                        : 0
                    }}
                  </td>
                  <div
                    class="absolute inset-1.5 inset-x-[5%] flex"
                    v-if="
                      popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')]
                        .average_goals_against &&
                      popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].average_goals_against
                    "
                  >
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')]
                              .average_goals_against,
                            popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')]
                              .average_goals_against
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')]
                              .average_goals_against,
                            popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')]
                              .average_goals_against
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </tr>
                <tr
                  class="flex relative"
                  v-if="
                    (popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].average_XG &&
                      popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].average_XG.F > 0) ||
                    (popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].average_XG &&
                      popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].average_XG.F > 0)
                  "
                >
                  <td :class="`w-1/4 relative z-10 p-2`">
                    {{
                      popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].average_XG.F
                        ? popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].average_XG.F
                        : ""
                    }}
                  </td>
                  <td :class="`relative z-10 p-2 flex-1`">Avg. xG For</td>
                  <td :class="`w-1/4 relative z-10 p-2`">
                    {{
                      popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].average_XG.F
                        ? popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].average_XG.F
                        : ""
                    }}
                  </td>
                  <div
                    class="absolute inset-1.5 inset-x-[5%] flex"
                    v-if="
                      popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].average_xg_for &&
                      popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].average_xg_for
                    "
                  >
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].average_xg_for,
                            popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].average_xg_for
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].average_xg_for,
                            popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].average_xg_for
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </tr>
                <tr
                  class="flex relative"
                  v-if="
                    (popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].average_XG &&
                      popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].average_XG.A > 0) ||
                    (popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].average_XG &&
                      popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].average_XG.A > 0)
                  "
                >
                  <td :class="`w-1/4 relative z-10 p-2`">
                    {{
                      popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].average_XG.A
                        ? popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].average_XG.A
                        : ""
                    }}
                  </td>
                  <td :class="`relative z-10 p-2 flex-1`">Avg. xG Against</td>
                  <td :class="`w-1/4 relative z-10 p-2`">
                    {{
                      popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].average_XG.A
                        ? popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].average_XG.A
                        : ""
                    }}
                  </td>
                  <div
                    class="absolute inset-1.5 inset-x-[5%] flex"
                    v-if="
                      popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].average_xg_against &&
                      popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].average_xg_against
                    "
                  >
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')]
                              .average_xg_against,
                            popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')]
                              .average_xg_against
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')]
                              .average_xg_against,
                            popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')]
                              .average_xg_against
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </tr>
                <tr class="relative overflow-hidden bg-white flex" 
                v-if="
                    (popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_shots_per_game &&
                      popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_shots_per_game > 0) ||
                    (popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].avg_shots_per_game &&
                      popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].avg_shots_per_game > 0)
                  "
                >
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_shots_per_game
                        ? popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_shots_per_game
                        : 0
                    }}
                  </td>
                  <td :class="`flex-1 relative z-10 p-2`">Avg Shots</td>
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].avg_shots_per_game
                        ? popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].avg_shots_per_game
                        : 0
                    }}
                  </td>
                  <div
                    class="absolute inset-1.5 inset-x-[5%] flex"
                    v-if="
                      popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].avg_shots_per_game &&
                      popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].avg_shots_per_game
                    "
                  >
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')]
                              .avg_shots_per_game,
                            popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')]
                              .avg_shots_per_game
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')]
                              .avg_shots_per_game,
                            popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')]
                              .avg_shots_per_game
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </tr>
                <tr class="relative overflow-hidden bg-white flex"
                v-if="
                    (popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_shots_on_target_per_game &&
                      popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_shots_on_target_per_game > 0) ||
                    (popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].avg_shots_on_target_per_game &&
                      popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].avg_shots_on_target_per_game > 0)
                  "
                >
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_shots_on_target_per_game
                        ? popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_shots_on_target_per_game
                        : 0
                    }}
                  </td>
                  <td :class="`flex-1 relative z-10 p-2`">Avg Shots On Target</td>
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].avg_shots_on_target_per_game
                        ? popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].avg_shots_on_target_per_game
                        : 0
                    }}
                  </td>
                  <div
                    class="absolute inset-1.5 inset-x-[5%] flex"
                    v-if="
                      popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].avg_shots_on_target_per_game &&
                      popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].avg_shots_on_target_per_game
                    "
                  >
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')]
                              .avg_shots_on_target_per_game,
                            popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')]
                              .avg_shots_on_target_per_game
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')]
                              .avg_shots_on_target_per_game,
                            popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')]
                              .avg_shots_on_target_per_game
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </tr>
                <tr v-if="(popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_corners_per_game && popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_corners_per_game > 0) || (popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_corners_per_game && popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].avg_corners_per_game > 0)" class="relative overflow-hidden bg-white flex">
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_corners_per_game
                        ? popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_corners_per_game
                        : 0
                    }}
                  </td>
                  <td :class="`flex-1 relative z-10 p-2`">Avg Corners</td>
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].avg_corners_per_game
                        ? popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].avg_corners_per_game
                        : 0
                    }}
                  </td>
                  <div
                    class="absolute inset-1.5 inset-x-[5%] flex"
                    v-if="
                      popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].avg_corners_per_game &&
                      popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].avg_corners_per_game
                    "
                  >
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')]
                              .avg_corners_per_game,
                            popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')]
                              .avg_corners_per_game
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')]
                              .avg_corners_per_game,
                            popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')]
                              .avg_corners_per_game
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </tr>
                <tr class="relative overflow-hidden bg-white flex">
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_booking_points
                        ? popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_booking_points
                        : 0
                    }}
                  </td>
                  <td :class="`flex-1 relative z-10 p-2`">
                    Avg Booking Points
                  </td>
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].avg_booking_points
                        ? popOverData.away_team.stats[getTimeFrameDropdown('teamstats')].avg_booking_points
                        : 0
                    }}
                  </td>
                  <div
                    class="absolute inset-1.5 inset-x-[5%] flex"
                    v-if="
                      popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].avg_booking_points &&
                      popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].avg_booking_points
                    "
                  >
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')]
                              .avg_booking_points,
                            popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')]
                              .avg_booking_points
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')]
                              .avg_booking_points,
                            popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')]
                              .avg_booking_points
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </tr>
                <!-- <tr
                  class="flex relative"
                  v-if="
                    (popOverData.home_team.team_ranking &&
                      popOverData.home_team.team_ranking > 0) ||
                    (popOverData.away_team.team_ranking &&
                      popOverData.away_team.team_ranking > 0)
                  "
                >
                  <td :class="`relative z-10 p-2`">
                    {{ addNth(popOverData.home_team.team_ranking) }}
                  </td>
                  <td :class="`relative z-10 p-2 flex-1`">
                    Club World Ranking
                  </td>
                  <td :class="`relative z-10 p-2`">
                    {{ addNth(popOverData.away_team.team_ranking) }}
                  </td>
                  <div
                    class="absolute inset-1.5 inset-x-[5%] flex"
                    v-if="
                      popOverData.home_team.stats_charts.team_ranking &&
                      popOverData.away_team.stats_charts.team_ranking
                    "
                  >
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts.team_ranking,
                            popOverData.away_team.stats_charts.team_ranking
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts.team_ranking,
                            popOverData.home_team.stats_charts.team_ranking
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </tr> -->
                <tr
                  class="flex relative"
                  v-if="
                    (popOverData.home_team.fifa_world_ranking &&
                      popOverData.home_team.fifa_world_ranking > 0) ||
                    (popOverData.away_team.fifa_world_ranking &&
                      popOverData.away_team.fifa_world_ranking > 0)
                  "
                >
                  <td :class="`relative z-10 p-2`">
                    {{ addNth(popOverData.home_team.fifa_world_ranking) }}
                  </td>
                  <td :class="`relative z-10 p-2 flex-1`">
                    FIFA World Ranking
                  </td>
                  <td :class="`relative z-10 p-2`">
                    {{ addNth(popOverData.away_team.fifa_world_ranking) }}
                  </td>
                  <div
                    class="absolute inset-1.5 inset-x-[5%] flex"
                    v-if="
                      popOverData.home_team.stats_charts.fifa_world_ranking &&
                      popOverData.away_team.stats_charts.fifa_world_ranking
                    "
                  >
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts.fifa_world_ranking,
                            popOverData.away_team.stats_charts.fifa_world_ranking
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts.fifa_world_ranking,
                            popOverData.home_team.stats_charts.fifa_world_ranking
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </tr>
                <tr
                  class="flex relative"
                  v-if="
                    popOverData.home_team.market_value &&
                    popOverData.away_team.market_value
                  "
                >
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.home_team.market_value
                        ? popOverData.home_team.market_value
                        : ""
                    }}
                  </td>
                  <td :class="`flex-1 relative z-10 p-2`">
                    Squad Market Value
                  </td>
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.away_team.market_value
                        ? popOverData.away_team.market_value
                        : 0
                    }}
                  </td>
                  <div
                    class="absolute inset-1.5 inset-x-[5%] flex"
                    v-if="
                      popOverData.home_team.stats_charts.market_value &&
                      popOverData.away_team.stats_charts.market_value
                    "
                  >
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts.market_value,
                            popOverData.away_team.stats_charts.market_value
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts.market_value,
                            popOverData.home_team.stats_charts.market_value
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </tr>
                <tr
                  class="flex relative"
                  v-if="
                    (popOverData.home_team.market_value_league_rank &&
                      popOverData.home_team.market_value_league_rank != '0th') &&
                    (popOverData.away_team.market_value_league_rank &&
                      popOverData.away_team.market_value_league_rank  != '0th')
                  "
                >
                  <td :class="`relative z-10 p-2`">
                    {{ addNth(popOverData.home_team.market_value_league_rank) }}
                  </td>
                  <td class="flex-1 p-2 relative z-10">
                    Squad Market Value League Ranking
                  </td>
                  <td :class="`relative z-10 p-2`">
                    {{ addNth(popOverData.away_team.market_value_league_rank) }}
                  </td>
                  <!-- <div class="absolute inset-1.5 inset-x-[5%] flex">
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts.market_value
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts.market_value
                          )
                        "
                      ></div>
                    </div>
                  </div> -->
                </tr>
                <!-- <tr class="flex relative overflow-hidden bg-white">
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.home_team.stats.average_first_goal_scored.home
                        ? popOverData.home_team.stats.average_first_goal_scored
                            .home
                        : 0
                    }}
                  </td>
                  <td class="p-2 relative z-10 flex-1">
                    Avg Time First Goal Scored
                  </td>
                  <td :class="`relative z-10 p-2 `">
                    {{
                      popOverData.away_team.stats.average_first_goal_scored.away
                        ? popOverData.away_team.stats.average_first_goal_scored
                            .away
                        : 0
                    }}
                  </td> -->

                <!-- </tr> -->
                <!-- <tr class="flex relative overflow-hidden bg-white">
                  <td :class="`relative z-10 p-2 `">
                    {{
                      popOverData.home_team.stats.average_first_goal_conceded
                        .home
                        ? popOverData.home_team.stats
                            .average_first_goal_conceded.home
                        : 0
                    }}
                  </td>
                  <td class="flex-1 p-2 relative z-10">
                    Avg Time First Goal Conceded
                  </td>
                  <td :class="`relative z-10 p-2 `">
                    {{
                      popOverData.away_team.stats.average_first_goal_conceded
                        .away
                        ? popOverData.away_team.stats
                            .average_first_goal_conceded.away
                        : 0
                    }}
                  </td> -->
                  <!-- <div class="absolute inset-1.5 inset-x-[5%] flex">
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts
                              .average_first_goal_conceded
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts
                              .average_first_goal_conceded
                          )
                        "
                      ></div>
                    </div>
                  </div> -->
                <!-- </tr> -->
              </table>
            </CardOverlayItem>

            <!-- <CardOverlayItem title="Team Profiles">
              <template v-if="gotHomeProfile && gotAwayProfile">
                <div class="">
                  <Radar
                    :chartOptions="chartOptions"
                    :chartData="chartData"
                  ></Radar>

                  <div class="mt-4 flex items-center justify-center relative">
                    <button
                      v-on:click="
                        showHowCalcuationModal = !showHowCalcuationModal
                      "
                      class="underline text-blue-600 text-sm"
                    >
                      How Are Team Profiles Calculated?
                    </button>
                    <div
                      v-if="showHowCalcuationModal"
                      class="absolute left-1/2 p-2 list-inside list-disc border text-left text-xs w-48 z-20 bottom-[140%] transform -translate-x-1/2 bg-white block"
                    >
                      <button
                        v-on:click="showHowCalcuationModal = false"
                        class="absolute right-2 top-2"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                      <p class="pt-8">
                        The raw data points in the table below are used to
                        calculate team profiles - teams in the top 5 leagues are
                        compared with each other, whereas teams elsewhere are
                        compared to the next 5. Data points are from this season
                        only.
                      </p>
                    </div>
                  </div>

                  <div class="mt-4">
                    <ul class="flex text-xxxs flex-wrap">
                      <li class="w-1/2 md:w-1/3 p-2">
                        <strong>ATT:</strong> Attack
                      </li>
                      <li class="w-1/2 md:w-1/3 p-2">
                        <strong>POSS:</strong> Possession
                      </li>
                      <li class="w-1/2 md:w-1/3 p-2">
                        <strong>LONG:</strong> Long Ball
                      </li>
                      <li class="w-1/2 md:w-1/3 p-2">
                        <strong>FINISH:</strong> Finishing
                      </li>
                      <li class="w-1/2 md:w-1/3 p-2">
                        <strong>SPF:</strong> Set Pieces For
                      </li>
                      <li class="w-1/2 md:w-1/3 p-2">
                        <strong>SPA:</strong> Set Pieces Against
                      </li>
                      <li class="w-1/2 md:w-1/3 p-2">
                        <strong>DEF:</strong> Defence
                      </li>
                      <li class="w-1/2 md:w-1/3 p-2">
                        <strong>PRESS:</strong> Pressing
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="mt-10 mb-10">
                  <table class="text-xs w-full border border-black">
                    <tr>
                      <th
                        class="hidden md:table-cell p-2 text-center uppercase italic bg-[#E59C98]"
                        width="17.5%"
                      >
                        Value
                      </th>
                      <th
                        class="p-2 text-center uppercase italic bg-[#E59C98]"
                        width="17.5%"
                      >
                        Percentile
                      </th>
                      <th
                        class="p-2 text-center uppercase italic bg-gray-300"
                        width="30%"
                      >
                        Attribute
                      </th>
                      <th
                        class="p-2 text-center uppercase italic bg-[#BDBDFB]"
                        width="17.5%"
                      >
                        Percentile
                      </th>
                      <th
                        class="hidden md:table-cell p-2 text-center uppercase italic bg-[#BDBDFB]"
                        width="17.5%"
                      >
                        Value
                      </th>
                    </tr>

                    <tr>
                      <td
                        class="hidden md:table-cell text-center p-2 bg-[#E59C98] bg-opacity-10"
                      >
                        {{
                          profileData.home
                            ? profileData.home.data.attack.npxg_xg_assist_per90
                            : "N/A"
                        }}
                      </td>
                      <td class="text-center p-2 bg-[#E59C98] bg-opacity-10">
                        {{
                          profileData.home
                            ? profileData.home.percentiles.attack
                            : "N/A"
                        }}
                      </td>
                      <td class="text-center p-2 bg-gray-100">
                        <p>
                          <strong class="block text-center w-full uppercase"
                            >Attack</strong
                          ><small class="hidden md:block">(npxG + xA)</small>
                        </p>
                      </td>
                      <td class="text-center p-2 bg-[#BDBDFB] bg-opacity-10">
                        {{
                          profileData.away
                            ? profileData.away.percentiles.attack
                            : "N/A"
                        }}
                      </td>
                      <td
                        class="hidden md:table-cell text-center p-2 bg-[#BDBDFB] bg-opacity-10"
                      >
                        {{
                          profileData.away
                            ? profileData.away.data.attack.npxg_xg_assist_per90
                            : "N/A"
                        }}
                      </td>
                    </tr>

                    <tr>
                      <td
                        class="hidden md:table-cell text-center p-2 bg-[#E59C98] bg-opacity-30"
                      >
                        {{
                          profileData.home
                            ? profileData.home.data.possession.avg_possession
                            : "N/A"
                        }}
                      </td>
                      <td class="text-center p-2 bg-[#E59C98] bg-opacity-30">
                        {{
                          profileData.home
                            ? profileData.home.percentiles.possession
                            : "N/A"
                        }}
                      </td>
                      <td class="text-center p-2 bg-gray-200">
                        <p>
                          <strong class="block text-center w-full uppercase"
                            >Possession</strong
                          ><small class="hidden md:block">(Avg %)</small>
                        </p>
                      </td>
                      <td class="text-center p-2 bg-[#BDBDFB] bg-opacity-30">
                        {{
                          profileData.away
                            ? profileData.away.percentiles.possession
                            : "N/A"
                        }}
                      </td>
                      <td
                        class="hidden md:table-cell text-center p-2 bg-[#BDBDFB] bg-opacity-30"
                      >
                        {{
                          profileData.away
                            ? profileData.away.data.possession.avg_possession
                            : "N/A"
                        }}
                      </td>
                    </tr>

                    <tr>
                      <td
                        class="hidden md:table-cell text-center p-2 bg-[#E59C98] bg-opacity-10"
                      >
                        {{
                          profileData.home
                            ? profileData.home.data.long_ball
                                .percent_long_passes
                            : "N/A"
                        }}
                      </td>
                      <td class="text-center p-2 bg-[#E59C98] bg-opacity-10">
                        {{
                          profileData.home
                            ? profileData.home.percentiles.long_ball
                            : "N/A"
                        }}
                      </td>
                      <td class="text-center p-2 bg-gray-100">
                        <p>
                          <strong class="block text-center w-full uppercase"
                            >Long ball</strong
                          ><small class="hidden md:block">(% long balls)</small>
                        </p>
                      </td>
                      <td class="text-center p-2 bg-[#BDBDFB] bg-opacity-10">
                        {{
                          profileData.away
                            ? profileData.away.percentiles.long_ball
                            : "N/A"
                        }}
                      </td>
                      <td
                        class="hidden md:table-cell text-center p-2 bg-[#BDBDFB] bg-opacity-10"
                      >
                        {{
                          profileData.away
                            ? profileData.away.data.long_ball
                                .percent_long_passes
                            : "N/A"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="hidden md:table-cell text-center p-2 bg-[#E59C98] bg-opacity-30"
                      >
                        {{
                          profileData.home
                            ? profileData.home.data.finishing.npxg_net
                            : "N/A"
                        }}
                      </td>
                      <td class="text-center p-2 bg-[#E59C98] bg-opacity-30">
                        {{
                          profileData.home
                            ? profileData.home.percentiles.finishing
                            : "N/A"
                        }}
                      </td>
                      <td class="text-center p-2 bg-gray-200">
                        <p>
                          <strong class="block text-center w-full uppercase"
                            >Finishing</strong
                          ><small class="hidden md:block">(npxG net)</small>
                        </p>
                      </td>
                      <td class="text-center p-2 bg-[#BDBDFB] bg-opacity-30">
                        {{
                          profileData.away
                            ? profileData.away.percentiles.finishing
                            : "N/A"
                        }}
                      </td>
                      <td
                        class="hidden md:table-cell text-center p-2 bg-[#BDBDFB] bg-opacity-30"
                      >
                        {{
                          profileData.away
                            ? profileData.away.data.finishing.npxg_net
                            : "N/A"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="hidden md:table-cell text-center p-2 bg-[#E59C98] bg-opacity-10"
                      >
                        {{
                          profileData.home
                            ? profileData.home.data.defence.npxg_xg_assist_per90
                            : "N/A"
                        }}
                      </td>
                      <td class="text-center p-2 bg-[#E59C98] bg-opacity-10">
                        {{
                          profileData.home
                            ? profileData.home.percentiles.defence
                            : "N/A"
                        }}
                      </td>
                      <td class="text-center p-2 bg-gray-100">
                        <p>
                          <strong class="block text-center w-full uppercase"
                            >Defence</strong
                          ><small class="hidden md:block"
                            >(npxG + xA conceded)</small
                          >
                        </p>
                      </td>
                      <td class="text-center p-2 bg-[#BDBDFB] bg-opacity-10">
                        {{
                          profileData.away
                            ? profileData.away.percentiles.defence
                            : "N/A"
                        }}
                      </td>
                      <td
                        class="hidden md:table-cell text-center p-2 bg-[#BDBDFB] bg-opacity-10"
                      >
                        {{
                          profileData.away
                            ? profileData.away.data.defence.npxg_xg_assist_per90
                            : "N/A"
                        }}
                      </td>
                    </tr>

                    <tr>
                      <td
                        class="hidden md:table-cell text-center p-2 bg-[#E59C98] bg-opacity-30"
                      >
                        {{
                          profileData.home
                            ? profileData.home.data.pressing.ppda
                            : "N/A"
                        }}
                      </td>
                      <td class="text-center p-2 bg-[#E59C98] bg-opacity-30">
                        {{
                          profileData.home
                            ? profileData.home.percentiles.pressing
                            : "N/A"
                        }}
                      </td>
                      <td class="text-center p-2 bg-gray-200">
                        <p>
                          <strong class="block text-center w-full uppercase"
                            >Pressing</strong
                          ><small class="hidden md:block">(PPDA)</small>
                        </p>
                      </td>
                      <td class="text-center p-2 bg-[#BDBDFB] bg-opacity-30">
                        {{
                          profileData.away
                            ? profileData.away.percentiles.pressing
                            : "N/A"
                        }}
                      </td>
                      <td
                        class="hidden md:table-cell text-center p-2 bg-[#BDBDFB] bg-opacity-30"
                      >
                        {{
                          profileData.away
                            ? profileData.away.data.pressing.ppda
                            : "N/A"
                        }}
                      </td>
                    </tr>

                    <tr>
                      <td
                        class="hidden md:table-cell text-center p-2 bg-[#E59C98] bg-opacity-10"
                      >
                        {{
                          profileData.home
                            ? +profileData.home.data.set_peices_for
                                .gca_deadball_for +
                              +profileData.home.data.set_peices_for
                                .sca_deadball_for
                            : "N/A"
                        }}
                      </td>
                      <td class="text-center p-2 bg-[#E59C98] bg-opacity-10">
                        {{
                          profileData.home
                            ? profileData.home.percentiles.set_peices_for
                            : "N/A"
                        }}
                      </td>
                      <td class="text-center p-2 bg-gray-100">
                        <p>
                          <strong class="block text-center w-full uppercase"
                            >Set Pieces For</strong
                          ><small class="hidden md:block"
                            >(SCA + GCA dead ball)</small
                          >
                        </p>
                      </td>
                      <td class="text-center p-2 bg-[#BDBDFB] bg-opacity-10">
                        {{
                          profileData.away
                            ? profileData.away.percentiles.set_peices_for
                            : "N/A"
                        }}
                      </td>
                      <td
                        class="hidden md:table-cell text-center p-2 bg-[#BDBDFB] bg-opacity-10"
                      >
                        {{
                          profileData.away
                            ? +profileData.away.data.set_peices_for
                                .gca_deadball_for +
                              +profileData.away.data.set_peices_for
                                .sca_deadball_for
                            : "N/A"
                        }}
                      </td>
                    </tr>

                    <tr>
                      <td
                        class="hidden md:table-cell text-center p-2 bg-[#E59C98] bg-opacity-30"
                      >
                        {{
                          profileData.home
                            ? +profileData.home.data.set_peices_against
                                .gca_deadball_against +
                              +profileData.home.data.set_peices_against
                                .sca_deadball_against
                            : "N/A"
                        }}
                      </td>
                      <td class="text-center p-2 bg-[#E59C98] bg-opacity-30">
                        {{
                          profileData.home
                            ? profileData.home.percentiles.set_peices_against
                            : "N/A"
                        }}
                      </td>
                      <td class="text-center p-2 bg-gray-200">
                        <p>
                          <strong class="block text-center w-full uppercase"
                            >Set Pieces Against</strong
                          ><small class="hidden md:block"
                            >(SCA + GCA dead ball conceded)</small
                          >
                        </p>
                      </td>
                      <td class="text-center p-2 bg-[#BDBDFB] bg-opacity-30">
                        {{
                          profileData.away
                            ? profileData.away.percentiles.set_peices_against
                            : "N/A"
                        }}
                      </td>
                      <td
                        class="hidden md:table-cell text-center p-2 bg-[#BDBDFB] bg-opacity-30"
                      >
                        {{
                          profileData.away
                            ? +profileData.away.data.set_peices_against
                                .gca_deadball_against +
                              +profileData.away.data.set_peices_against
                                .sca_deadball_against
                            : "N/A"
                        }}
                      </td>
                    </tr>
                  </table>
                </div>
              </template>
              <p v-else class="text-center text-sm text-gray-300">
                <template v-if="!profileData.home && !profileData.away">
                  Team profiles are currently not supported for this match.
                </template>
                <template v-else> Loading, please wait... </template>
              </p>
            </CardOverlayItem> -->

            <!-- <CardOverlayItem title="Lineups" class="">
              <template v-if="groupedByPositionAway.G">
                <div class="mx-auto mt-2 mb-4 max-w-[300px] relative">
                  <img src="@/assets/pitch.svg" class="w-full" />
                  <div
                    class="inset-0 absolute flex flex-col py-[5px] space-y-[5px]"
                  >
                    <div
                      class="flex-1 flex justify-center"
                      v-if="groupedByPositionHome.G"
                    >
                      <div
                        class="flex w-1/4 items-center justify-center flex-col"
                        v-for="(player, key) in groupedByPositionHome.G"
                        :key="key"
                      >
                        <img
                          class="w-[23px] h-auto"
                          src="@/assets/shirt-black.svg"
                        /><span
                          class="bg-gray-400 text-[8px] px-1 text-white"
                          >{{ getLastName(player.player_name) }}</span
                        >
                      </div>
                    </div>
                    <div
                      class="flex-1 flex justify-center"
                      v-if="groupedByPositionHome.D"
                    >
                      <div
                        class="flex w-1/4 items-center justify-center flex-col"
                        v-for="(player, key) in groupedByPositionHome.D"
                        :key="key"
                      >
                        <img
                          class="w-[23px] h-auto"
                          src="@/assets/shirt-black.svg"
                        /><span
                          class="bg-gray-400 text-[8px] px-1 text-white"
                          >{{ getLastName(player.player_name) }}</span
                        >
                      </div>
                    </div>
                    <div
                      class="flex-1 flex justify-center"
                      v-if="groupedByPositionHome.M"
                    >
                      <div
                        class="flex w-1/4 items-center justify-center flex-col"
                        v-for="(player, key) in groupedByPositionHome.M"
                        :key="key"
                      >
                        <img
                          class="w-[23px] h-auto"
                          src="@/assets/shirt-black.svg"
                        /><span
                          class="bg-gray-400 text-[8px] px-1 text-white"
                          >{{ getLastName(player.player_name) }}</span
                        >
                      </div>
                    </div>
                    <div
                      class="flex-1 flex justify-center"
                      v-if="groupedByPositionHome.A"
                    >
                      <div
                        class="flex w-1/4 items-center justify-center flex-col"
                        v-for="(player, key) in groupedByPositionHome.A"
                        :key="key"
                      >
                        <img
                          class="w-[23px] h-auto"
                          src="@/assets/shirt-black.svg"
                        /><span
                          class="bg-gray-400 text-[8px] px-1 text-white"
                          >{{ getLastName(player.player_name) }}</span
                        >
                      </div>
                    </div>
                    <div class="h-[10%]"></div>

                    <div
                      class="flex-1 flex justify-center"
                      v-if="groupedByPositionAway.A"
                    >
                      <div
                        class="flex w-1/4 items-center justify-center flex-col"
                        v-for="(player, key) in groupedByPositionAway.A"
                        :key="key"
                      >
                        <img
                          class="w-[23px] h-auto"
                          src="@/assets/shirt-grey.svg"
                        /><span
                          class="bg-gray-400 text-[8px] px-1 text-white"
                          >{{ getLastName(player.player_name) }}</span
                        >
                      </div>
                    </div>
                    <div
                      class="flex-1 flex justify-center"
                      v-if="groupedByPositionAway.M"
                    >
                      <div
                        class="flex w-1/4 items-center justify-center flex-col"
                        v-for="(player, key) in groupedByPositionAway.M"
                        :key="key"
                      >
                        <img
                          class="w-[23px] h-auto"
                          src="@/assets/shirt-grey.svg"
                        /><span
                          class="bg-gray-400 text-[8px] px-1 text-white"
                          >{{ getLastName(player.player_name) }}</span
                        >
                      </div>
                    </div>
                    <div
                      class="flex-1 flex justify-center"
                      v-if="groupedByPositionAway.D"
                    >
                      <div
                        class="flex w-1/4 items-center justify-center flex-col"
                        v-for="(player, key) in groupedByPositionAway.D"
                        :key="key"
                      >
                        <img
                          class="w-[23px] h-auto"
                          src="@/assets/shirt-grey.svg"
                        /><span
                          class="bg-gray-400 text-[8px] px-1 text-white"
                          >{{ getLastName(player.player_name) }}</span
                        >
                      </div>
                    </div>
                    <div
                      class="flex-1 flex justify-center"
                      v-if="groupedByPositionAway.G"
                    >
                      <div
                        class="flex w-1/4 items-center justify-center flex-col"
                        v-for="(player, key) in groupedByPositionAway.G"
                        :key="key"
                      >
                        <img
                          class="w-[23px] h-auto"
                          src="@/assets/shirt-grey.svg"
                        /><span
                          class="bg-gray-400 text-[8px] px-1 text-white"
                          >{{ getLastName(player.player_name) }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <h2
                  class="text-center uppercase text-xs text-gray-500 font-bold"
                >
                  Substitutes
                </h2>
                <div class="flex text-gray-400 text-xxs">
                  <div class="flex-1" v-if="popOverData.home_team.bench">
                    <p
                      v-for="(substition, key) in popOverData.home_team.bench"
                      :key="key"
                    >
                      {{ substition.player_name }}
                    </p>
                  </div>
                  <div
                    class="flex-1 text-right"
                    v-if="popOverData.away_team.bench"
                  >
                    <p
                      v-for="(substition, key) in popOverData.away_team.bench"
                      :key="key"
                    >
                      {{ substition.player_name }}
                    </p>
                  </div>
                </div>
              </template>
              <div v-else class="flex items-start align-top space-x-4">
                <span
                  class="w-full align-bottom py-2 text-center text-gray-500 text-xs"
                >
                  Lineup information is shown 1 hour before kickoff
                </span>
              </div>
            </CardOverlayItem> -->
            
            <CardOverlayItem title="Goal Scorers" class="overflow-visible">
              <h3 class="text-gray-500 text-center text-xs font-bold p-1.5 pt-3">
                All Competitions
                <button
                  v-on:click="toggleTimeFrameDropdown('goal_scorers')"
                  class="text-blue-700 font-bold underline"
                >
                  {{ getTimeFrameDropdown('goal_scorers') }}<svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-3 text-blue-700 inline-block pb-1"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="3"
                      viewBox="0 0 24 24"
                      :class="{ 'rotate-180': open }"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19 9l-7 7-7-7"
                      />
                  </svg>
                </button>
              </h3>
              <div class="w-1/2" v-if="toggleTimeFrameDropdowns.goal_scorers">
                  <div
                    class="bg-white w-16 absolute z-10 border border-gray-400 ml-[50%]"
                  >
                  <div>
                    <button
                      v-on:click="setTimeFrameDropdown('goal_scorers', 'season')"
                      class="text-xs font-bold border-t-0 p-1 bg-white text-blue-700 flex items-center justify-center"
                    >
                      season&nbsp; 
                    </button>
                  </div>
                  <div class="border-gray-200 border-t-2">
                    <button
                      v-on:click="setTimeFrameDropdown('goal_scorers', 'last_5')"
                      class="text-xs font-bold p-1 bg-white text-blue-700 flex items-center justify-center"
                    >
                      last 5&nbsp;
                    </button>
                  </div>
                  <div class="border-gray-200 border-t-2">
                    <button
                      v-on:click="setTimeFrameDropdown('goal_scorers', 'last_10')"
                      class="text-xs font-bold p-1 bg-white text-blue-700 flex items-center justify-center"
                    >
                      last 10
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="space-y-2 mt-3"
                :class="{ 'only-5-toggle': goalScorersHomeShort }"
                v-if="
                  popOverData.home_team.goal_scorers &&
                  popOverData.home_team.goal_scorers[getTimeFrameDropdown('goal_scorers')].length > 0
                "
              >
                <div class="flex">
                  <h3 class="w-2/5 uppercase text-xs text-gray-500 font-bold">
                    {{ popOverData.home_team.name }}
                  </h3>
                  <div class="flex-1 text-right">
                    <div class="group relative inline-block">
                      <img
                        class="w-3 inline-block relative left-[2px]"
                        src="@/assets/icons/clock.png"
                        title="Average Mins per game"
                      />
                      <ul
                        class="absolute right-0 p-1 border text-left text-[10px] w-48 z-20 bottom-full bg-white hidden group-hover:block"
                      >
                        <li>Average Mins per game</li>
                      </ul>
                    </div>
                  </div>
                  <div class="flex-1 text-right">
                    <div class="group relative inline-block">
                      <img
                        class="w-3 inline-block relative left-[2px]"
                        src="@/assets/icons/goal-icon.png"
                        title="Goals"
                      />
                      <ul
                        class="absolute right-0 p-1 border text-left text-[10px] w-48 z-20 bottom-full bg-white hidden group-hover:block"
                      >
                        <li>Total Goals This Season</li>
                      </ul>
                    </div>
                  </div>
                  <div class="flex-1 text-right">
                    <div class="group relative inline-block">
                      <img
                        class="w-5 inline-block relative left-[2px]"
                        src="@/assets/icons/goal-percent2.png"
                        title="Percentage to Score"
                      />
                      <ul
                        class="absolute right-0 p-1 border text-left text-[10px] w-48 z-20 bottom-full bg-white hidden group-hover:block"
                      >
                        <li>AI Chance of Scoring</li>
                      </ul>
                    </div>
                  </div>
                  <div class="flex-1 text-right">
                    <div class="group relative inline-block">
                      <img
                        class="w-3 inline-block relative"
                        src="@/assets/icons/assist.png"
                        title="Assists"
                      />
                      <ul
                        class="absolute right-0 p-1 border text-left text-[10px] w-48 z-20 bottom-full bg-white hidden group-hover:block"
                      >
                        <li>Total Assists This Season</li>
                      </ul>
                    </div>
                  </div>
                  <div class="flex-1 text-right">
                    <div class="group relative inline-block">
                      <img
                        class="w-3 inline-block"
                        src="@/assets/icons/shots.jpg"
                        title="Avg Total Shots"
                      />
                      <ul
                        class="absolute right-0 p-1 border text-left text-[10px] w-48 z-20 bottom-full bg-white hidden group-hover:block"
                      >
                        <li>Avg. Shots Per 90</li>
                      </ul>
                    </div>
                  </div>
                  <div class="flex-1 text-right">
                    <div class="group relative inline-block">
                      <img
                        class="w-3 inline-block"
                        src="@/assets/icons/target.png"
                        title="Avg Shots on Target"
                      />
                      <ul
                        class="absolute right-0 p-1 border text-left text-[10px] w-48 z-20 bottom-full bg-white hidden group-hover:block"
                      >
                        <li>Avg. Shots on Target Per 90</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  class="text-xxs only-5-item"
                  v-for="(item, key) in popOverData.home_team.goal_scorers[getTimeFrameDropdown('goal_scorers')]"
                  :key="key"
                >
                  <p
                    class="flex"
                    v-if="
                      'injured_players' in popOverData.home_team &&
                      popOverData.home_team.injured_players.includes(
                        item.player_short_name
                      ) ||
                      popOverData.home_team.suspended_players.includes(
                        item.player_short_name
                      )
                    "
                  >
                    <span class="w-2/5 text-red-500"
                      >{{ item.player_short_name }}
                      <img
                        v-if="
                          popOverData.home_team.injured_players.includes(
                            item.player_short_name
                          )
                        "
                        class="w-3 inline-block relative left-[2px]"
                        src="@/assets/icons/red-cross.png"
                        title="Injured"
                      />
                      <img
                        v-if="
                          'suspended_players' in popOverData.home_team &&
                          popOverData.home_team.suspended_players.includes(
                            item.player_short_name
                          )
                        "
                        class="w-3 inline-block relative left-[2px]"
                        src="@/assets/icons/yellowred.png"
                        title="Suspended"
                      />
                    </span>
                    <span class="flex-1 text-right text-red-500">{{
                      item.avg_minutes
                    }}</span>
                    <span class="flex-1 text-right text-red-500">{{
                      item.goals_all_comps
                    }}</span>
                    <span class="flex-1 text-right text-red-500">-</span>
                    <span class="flex-1 text-right pr-[2px] text-red-500">{{
                      item.assists
                    }}</span>
                    <span class="flex-1 text-right text-red-500">{{
                      Math.round(item.avg_shots_total * 10) / 10
                    }}</span>
                    <span class="flex-1 text-right text-red-500">{{
                      Math.round(item.avg_shots_on_target * 10) / 10
                    }}</span>
                  </p>
                  <p class="flex" v-else>
                    <span class="w-2/5 text-gray-400">{{ item.name }} </span>
                    <span class="flex-1 text-right">{{
                      item.avg_minutes
                    }}</span>
                    <span class="flex-1 text-right">{{
                      item.goals_all_comps
                    }}</span>
                    <span
                      class="flex-1 text-right"
                      v-if="item.goal_prediction"
                      >{{ item.goal_prediction }}</span
                    >
                    <span class="flex-1 text-right" v-else>-</span>
                    <span class="flex-1 text-right pr-[2px]">{{
                      item.assists
                    }}</span>
                    <span class="flex-1 text-right">{{
                      Math.round(item.avg_shots_total * 10) / 10
                    }}</span>
                    <span class="flex-1 text-right">{{
                      Math.round(item.avg_shots_on_target * 10) / 10
                    }}</span>
                  </p>
                </div>
                <div class="flex items-center justify-center mt-4">
                  <button
                    v-on:click="toggleOnly5('goalScorersHomeShort')"
                    class="text-gray-400 underline text-xxs"
                  >
                    Show <template v-if="goalScorersHomeShort">more</template
                    ><template v-else>less</template>
                  </button>
                </div>
              </div>
              <div v-else class="flex items-start align-top space-x-4">
                <span
                  class="w-full align-bottom py-2 text-center text-gray-500 text-xs"
                >
                  No recent home team goal scorers
                </span>
              </div>

              <div
                class="space-y-2 mt-10"
                v-if="
                  popOverData.away_team.goal_scorers &&
                  popOverData.away_team.goal_scorers[getTimeFrameDropdown('goal_scorers')].length > 0
                "
                :class="{ 'only-5-toggle': goalScorersAwayShort }"
              >
                <div class="flex">
                  <h3 class="w-2/5 uppercase text-xs text-gray-500 font-bold">
                    {{ popOverData.away_team.name }}
                  </h3>
                  <div class="flex-1 text-right">
                    <div class="group relative inline-block">
                      <img
                        class="w-3 inline-block relative left-[2px]"
                        src="@/assets/icons/clock.png"
                        title="Average Mins per game"
                      />
                      <ul
                        class="absolute right-0 p-1 border text-left text-[10px] w-48 z-20 bottom-full bg-white hidden group-hover:block"
                      >
                        <li>Average Mins per game</li>
                      </ul>
                    </div>
                  </div>
                  <div class="flex-1 text-right">
                    <div class="group relative inline-block">
                      <img
                        class="w-3 inline-block relative left-[2px]"
                        src="@/assets/icons/goal-icon.png"
                        title="Goals"
                      />
                      <ul
                        class="absolute right-0 p-1 border text-left text-[10px] w-48 z-20 bottom-full bg-white hidden group-hover:block"
                      >
                        <li>Total Goals This Season</li>
                      </ul>
                    </div>
                  </div>
                  <div class="flex-1 text-right">
                    <div class="group relative inline-block">
                      <img
                        class="w-5 inline-block relative left-[2px]"
                        src="@/assets/icons/goal-percent2.png"
                        title="Percentage to Score"
                      />
                      <ul
                        class="absolute right-0 p-1 border text-left text-[10px] w-48 z-20 bottom-full bg-white hidden group-hover:block"
                      >
                        <li>AI Chance of Scoring</li>
                      </ul>
                    </div>
                  </div>
                  <div class="flex-1 text-right">
                    <div class="group relative inline-block">
                      <img
                        class="w-3 inline-block relative"
                        src="@/assets/icons/assist.png"
                        title="Assists"
                      />
                      <ul
                        class="absolute right-0 p-1 border text-left text-[10px] w-48 z-20 bottom-full bg-white hidden group-hover:block"
                      >
                        <li>Total Assists This Season</li>
                      </ul>
                    </div>
                  </div>
                  <div class="flex-1 text-right">
                    <div class="group relative inline-block">
                      <img
                        class="w-3 inline-block"
                        src="@/assets/icons/shots.jpg"
                        title="Avg Total Shots"
                      />
                      <ul
                        class="absolute right-0 p-1 border text-left text-[10px] w-48 z-20 bottom-full bg-white hidden group-hover:block"
                      >
                        <li>Avg. Shots Per 90</li>
                      </ul>
                    </div>
                  </div>
                  <div class="flex-1 text-right">
                    <div class="group relative inline-block">
                      <img
                        class="w-3 inline-block"
                        src="@/assets/icons/target.png"
                        title="Avg Shots on Target"
                      />
                      <ul
                        class="absolute right-0 p-1 border text-left text-[10px] w-48 z-20 bottom-full bg-white hidden group-hover:block"
                      >
                        <li>Avg. Shots on Target Per 90</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  class="text-xxs only-5-item"
                  v-for="(item, key) in popOverData.away_team.goal_scorers[getTimeFrameDropdown('goal_scorers')]"
                  :key="key"
                >
                  <p
                    class="flex"
                    v-if="
                      'injured_players' in popOverData.away_team &&
                      'suspended_players' in popOverData.away_team &&
                      popOverData.away_team.injured_players.includes(
                        item.name
                      ) ||
                      popOverData.away_team.suspended_players.includes(
                        item.name
                      )
                    "
                  >
                    <span class="w-2/5 text-red-500"
                      >{{ item.name }}
                      <img
                        v-if="
                          popOverData.away_team.injured_players.includes(
                            item.name
                          )
                        "
                        class="w-3 inline-block relative left-[2px]"
                        src="@/assets/icons/red-cross.png"
                        title="Injured"
                      />
                      <img
                        v-if="
                          'suspended_players' in popOverData.away_team &&
                          popOverData.away_team.suspended_players.includes(
                            item.name
                          )
                        "
                        class="w-3 inline-block relative left-[2px]"
                        src="@/assets/icons/yellowred.png"
                        title="Suspended"
                      />
                    </span>
                    <span class="flex-1 text-right text-red-500">{{
                      item.avg_minutes
                    }}</span>
                    <span class="flex-1 text-right text-red-500">{{
                      item.goals_all_comps
                    }}</span>
                    <span class="flex-1 text-right text-red-500">-</span>
                    <span class="flex-1 text-right pr-[2px] text-red-500">{{
                      item.assists
                    }}</span>
                    <span class="flex-1 text-right text-red-500">{{
                      Math.round(item.avg_shots_total * 10) / 10
                    }}</span>
                    <span class="flex-1 text-right text-red-500">{{
                      Math.round(item.avg_shots_on_target * 10) / 10
                    }}</span>
                  </p>
                  <p class="flex" v-else>
                    <span class="w-2/5 text-gray-400">{{ item.name }}</span>
                    <span class="flex-1 text-right">{{
                      item.avg_minutes
                    }}</span>
                    <span class="flex-1 text-right">{{
                      item.goals_all_comps
                    }}</span>
                    <span
                      class="flex-1 text-right"
                      v-if="item.goal_prediction"
                      >{{ item.goal_prediction }}</span
                    >
                    <span class="flex-1 text-right" v-else>-</span>
                    <span class="flex-1 text-right pr-[2px]">{{
                      item.assists
                    }}</span>
                    <span class="flex-1 text-right">{{
                      Math.round(item.avg_shots_total * 10) / 10
                    }}</span>
                    <span class="flex-1 text-right">{{
                      Math.round(item.avg_shots_on_target * 10) / 10
                    }}</span>
                  </p>
                </div>
                <div class="flex items-center justify-center mt-4">
                  <button
                    v-on:click="toggleOnly5('goalScorersAwayShort')"
                    class="text-gray-400 underline text-xxs"
                  >
                    Show <template v-if="goalScorersAwayShort">more</template
                    ><template v-else>less</template>
                  </button>
                </div>
              </div>
              <div v-else class="flex items-start align-top space-x-4">
                <span
                  class="w-full align-bottom py-2 text-center text-gray-500 text-xs"
                >
                  No recent away team goal scorers
                </span>
              </div>
            </CardOverlayItem>

            <CardOverlayItem title="Match Predictions">
              <h3 class="text-gray-500 text-center text-xs font-bold p-1.5">
                Team
              </h3>

              <table class="w-full text-center text-xs">
                <tr>
                  <td class="p-2 w-1/4">
                    <h3 class="text-gray-500 uppercase text-xs font-bold">
                      {{ popOverData.home_team.name }}
                    </h3>
                  </td>
                  <td class="p-2"></td>
                  <td class="p-2 w-1/4">
                    <h3 class="text-gray-500 uppercase text-xs font-bold">
                      {{ popOverData.away_team.name }}
                    </h3>
                  </td>
                </tr>
                <tr>
                  <td class="text-center p-2 w-1/4">
                    {{ Math.round(match.predictions.HT_clean_sheet) }}%
                  </td>
                  <td class="text-gray-400 p-2 flex-1">Clean Sheet</td>
                  <td class="text-center p-2">
                    {{ Math.round(match.predictions.AT_clean_sheet) }}%
                  </td>
                </tr>
                <tr>
                  <td class="text-center p-2 w-1/4">
                    {{ Math.round(match.predictions.HT_over_0_5) }}%
                  </td>
                  <td class="text-gray-400 p-2 flex-1">
                    Over 0.5 Goals Scored
                  </td>
                  <td class="text-center p-2">
                    {{ Math.round(match.predictions.AT_over_0_5) }}%
                  </td>
                </tr>
                <tr>
                  <td class="text-center p-2">
                    {{ Math.round(match.predictions.HT_over_1_5) }}%
                  </td>
                  <td class="text-gray-400 p-2 flex-1">
                    Over 1.5 Goals Scored
                  </td>
                  <td class="text-center p-2">
                    {{ Math.round(match.predictions.AT_over_1_5) }}%
                  </td>
                </tr>
                <!-- <tr>
                  <td class="text-center p-2">
                    {{ toScoreFirstHalf(match, popOverData, "home_team") }}%
                  </td>
                  <td class="text-gray-400 p-2 flex-1">To Score First Half</td>
                  <td class="text-center  p-2">
                    {{ toScoreFirstHalf(match, popOverData, "away_team") }}%
                  </td>
                </tr> -->
                <tr>
                  <td></td>
                  <td>
                    <h3
                      class="text-gray-500 text-center text-xs font-bold p-1.5 pt-3"
                    >
                      Match
                    </h3>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td class="text-gray-400 p-2 flex-1" colspan="3">
                    Both Teams to Score:
                    <span class="text-black"
                      >{{ Math.round(match.predictions.btts) }}%</span
                    >
                  </td>
                </tr>
                <tr>
                  <td class="text-gray-400 p-2 flex-1" colspan="3">
                    Most Likely Scorelines:<br />
                    <span
                      class="text-black"
                      v-for="(item, key) in match.predictions
                        .most_likely_score_lines"
                      :key="key"
                    >
                      <span class="text-brandGrey">{{ item.score }}</span>
                      {{ item.value }}<br />
                    </span>
                  </td>
                </tr>
                <!-- <tr>
                  <td class="text-gray-400 p-2 flex-1" colspan="3">
                    Goal In The First Half:
                    <span class="text-black"
                      >{{
                        toScoreFirstHalf(match, popOverData, "both_teams")
                      }}%</span
                    >
                  </td>
                </tr> -->
                <tr>
                  <td class="text-gray-400 p-2 flex-1" colspan="3">
                    Over 2.5 Goals:
                    <span class="text-black"
                      >{{ Math.round(match.predictions.FT_over_2_5) }}%</span
                    >
                  </td>
                </tr>
                <tr>
                  <td class="text-gray-400 p-2 flex-1" colspan="3">
                    Over 3.5 Goals:
                    <span class="text-black"
                      >{{ Math.round(match.predictions.FT_over_3_5) }}%</span
                    >
                  </td>
                </tr>
                <tr>
                  <td class="text-gray-400 p-2 flex-1" colspan="3">
                    Over 4.5 Goals:
                    <span class="text-black"
                      >{{ Math.round(match.predictions.FT_over_4_5) }}%</span
                    >
                  </td>
                </tr>
              </table>
            </CardOverlayItem>

            <CardOverlayItem title="Cards & Fouls" class="">
              <h3 class="text-gray-500 text-center text-xs font-bold p-1.5 pt-3">
                All Competitions 
                <button
                  v-on:click="toggleTimeFrameDropdown('cards_fouls')"
                  class="text-blue-700 font-bold underline"
                >
                  {{ getTimeFrameDropdown('cards_fouls') }}<svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-3 text-blue-700 inline-block pb-1"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="3"
                      viewBox="0 0 24 24"
                      :class="{ 'rotate-180': open }"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19 9l-7 7-7-7"
                      />
                  </svg>
                </button>
              </h3>
              <div class="w-1/2" v-if="toggleTimeFrameDropdowns.cards_fouls">
                  <div
                    class="bg-white w-16 absolute z-10 border border-gray-400 ml-[50%]"
                  >
                  <div>
                    <button
                      v-on:click="setTimeFrameDropdown('cards_fouls', 'season')"
                      class="text-xs font-bold border-t-0 p-1 bg-white text-blue-700 flex items-center justify-center"
                    >
                      season&nbsp; 
                    </button>
                  </div>
                  <div class="border-gray-200 border-t-2">
                    <button
                      v-on:click="setTimeFrameDropdown('cards_fouls', 'last_5')"
                      class="text-xs font-bold p-1 bg-white text-blue-700 flex items-center justify-center"
                    >
                      last 5&nbsp;
                    </button>
                  </div>
                  <div class="border-gray-200 border-t-2">
                    <button
                      v-on:click="setTimeFrameDropdown('cards_fouls', 'last_10')"
                      class="text-xs font-bold p-1 bg-white text-blue-700 flex items-center justify-center"
                    >
                      last 10
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="space-y-2 mt-3"
                v-if="
                  popOverData.home_team.cards_fouls &&
                  popOverData.home_team.cards_fouls[getTimeFrameDropdown('cards_fouls')].length > 0
                "
                :class="{ 'only-5-toggle': cardsHomeShort }"
              >
                <div class="flex">
                  <h3 class="w-1/2 uppercase text-xs text-gray-500 font-bold">
                    {{ popOverData.home_team.name }}
                  </h3>
                  <div class="flex-1 text-right">
                    <div class="group relative inline-block">
                      <img
                        class="w-3 inline-block relative left-[2px]"
                        src="@/assets/icons/clock.png"
                        title="Average Mins per game"
                      />
                      <ul
                        class="absolute right-0 p-1 border text-left text-[10px] w-48 z-20 bottom-full bg-white hidden group-hover:block"
                      >
                        <li>Average Mins per game</li>
                      </ul>
                    </div>
                  </div>
                  <div class="flex-1 text-right">
                    <div class="group relative inline-block">
                      <svg
                        class="inline-block"
                        width="8"
                        height="11"
                        viewBox="0 0 8 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 1C0 0.447715 0.447715 0 1 0L7 0C7.55228 0 8 0.447715 8 1V10C8 10.5523 7.55228 11 7 11H1C0.447715 11 0 10.5523 0 10L0 1Z"
                          fill="#FFEC3E"
                        />
                      </svg>
                      <ul
                        class="absolute right-0 p-1 border text-left text-[10px] w-48 z-20 bottom-full bg-white hidden group-hover:block"
                      >
                        <li>Yellow cards</li>
                      </ul>
                    </div>
                  </div>
                  <div class="flex-1 text-right">
                    <div class="group relative inline-block">
                      <img
                        class="w-3 inline-block relative left-[2px]"
                        src="@/assets/icons/card-percent.png"
                        title="Probability Booking"
                      />
                      <ul
                        class="absolute right-0 p-1 border text-left text-[10px] w-48 z-20 bottom-full bg-white hidden group-hover:block"
                      >
                        <li>Probability Booking</li>
                      </ul>
                    </div>
                  </div>
                  <div class="flex-1 text-right">
                    <div class="group relative inline-block">
                      <svg
                        class="inline-block"
                        width="8"
                        height="11"
                        viewBox="0 0 8 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M-3.8147e-06 1C-3.8147e-06 0.447715 0.447711 0 0.999996 0L7 0C7.55228 0 8 0.447715 8 1V10C8 10.5523 7.55228 11 7 11H0.999996C0.447711 11 -3.8147e-06 10.5523 -3.8147e-06 10L-3.8147e-06 1Z"
                          fill="#CF1818"
                        />
                      </svg>
                      <ul
                        class="absolute right-0 p-1 border text-left text-[10px] w-48 z-20 bottom-full bg-white hidden group-hover:block"
                      >
                        <li>Red cards</li>
                      </ul>
                    </div>
                  </div>
                  <div class="flex-1 text-right">
                    <div class="group relative inline-block">
                      <img
                        class="w-3 inline-block"
                        src="@/assets/icons/foul.png"
                      />
                      <ul
                        class="absolute right-0 p-1 border text-left text-[10px] w-48 z-20 bottom-full bg-white hidden group-hover:block"
                      >
                        <li>Average Fouls</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  class="text-xxs only-5-item"
                  v-for="(item, key) in popOverData.home_team.cards_fouls[getTimeFrameDropdown('cards_fouls')]"
                  :key="key"
                >
                  <p
                    class="flex"
                    v-if="
                      'injured_players' in popOverData.home_team &&
                      popOverData.home_team.injured_players.includes(
                        item.name
                      ) ||
                      popOverData.home_team.suspended_players.includes(
                        item.name
                      )
                    "
                  >
                    <span class="w-1/2 text-red-500"
                      >{{ item.name }}
                      <img
                        v-if="
                          popOverData.home_team.injured_players.includes(
                            item.name
                          )
                        "
                        class="w-3 inline-block relative left-[2px]"
                        src="@/assets/icons/red-cross.png"
                        title="Injured"
                      />
                      <img
                        v-if="
                          'suspended_players' in popOverData.home_team &&
                          popOverData.home_team.suspended_players.includes(
                            item.name
                          )
                        "
                        class="w-3 inline-block relative left-[2px]"
                        src="@/assets/icons/yellowred.png"
                        title="Suspended"
                      />
                    </span>
                    <span class="flex-1 text-right text-red-500">{{
                      item.avg_minutes
                    }}</span>
                    <span class="flex-1 text-right text-red-500">{{
                      item.yellow_cards_all_comps
                    }}</span>
                    <span class="flex-1 text-right text-red-500">-</span>
                    <span class="flex-1 text-right text-red-500">{{
                      item.red_cards_all_comps
                    }}</span>
                    <span class="flex-1 text-right text-red-500">{{
                      item.avg_fouls
                    }}</span>
                  </p>
                  <p class="flex" v-else>
                    <span class="w-1/2 text-gray-400">{{ item.name }}</span>
                    <span class="flex-1 text-right">{{
                      item.avg_minutes
                    }}</span>
                    <span class="flex-1 text-right">{{
                      item.yellow_cards_all_comps
                    }}</span>
                    <span
                      class="flex-1 text-right"
                      v-if="item.card_prediction"
                      >{{ item.card_prediction }}
                    </span>
                    <span class="flex-1 text-right" v-else>-</span>
                    <span class="flex-1 text-right">{{
                      item.red_cards_all_comps
                    }}</span>
                    <span class="flex-1 text-right">{{ item.avg_fouls }}</span>
                  </p>
                </div>
                <div class="flex items-center justify-center mt-4">
                  <button
                    v-on:click="toggleOnly5('cardsHomeShort')"
                    class="text-gray-400 underline text-xxs"
                  >
                    Show <template v-if="cardsHomeShort">more</template
                    ><template v-else>less</template>
                  </button>
                </div>
              </div>
              <div v-else class="flex items-start align-top space-x-4">
                <span
                  class="w-full align-bottom py-2 text-center text-gray-500 text-xs"
                >
                  No recent home team cards
                </span>
              </div>

              <div
                class="space-y-2 mt-10"
                v-if="
                  popOverData.away_team.cards_fouls &&
                  popOverData.away_team.cards_fouls[getTimeFrameDropdown('cards_fouls')].length > 0
                "
                :class="{ 'only-5-toggle': cardsAwayShort }"
              >
                <div class="flex">
                  <h3 class="w-1/2 uppercase text-xs text-gray-500 font-bold">
                    {{ popOverData.away_team.name }}
                  </h3>
                  <div class="flex-1 text-right">
                    <div class="group relative inline-block">
                      <img
                        class="w-3 inline-block relative left-[2px]"
                        src="@/assets/icons/clock.png"
                        title="Average Mins per game"
                      />
                      <ul
                        class="absolute right-0 p-1 border text-left text-[10px] w-48 z-20 bottom-full bg-white hidden group-hover:block"
                      >
                        <li>Average Mins per game</li>
                      </ul>
                    </div>
                  </div>
                  <div class="flex-1 text-right">
                    <div class="group relative inline-block">
                      <svg
                        class="inline-block"
                        width="8"
                        height="11"
                        viewBox="0 0 8 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 1C0 0.447715 0.447715 0 1 0L7 0C7.55228 0 8 0.447715 8 1V10C8 10.5523 7.55228 11 7 11H1C0.447715 11 0 10.5523 0 10L0 1Z"
                          fill="#FFEC3E"
                        />
                      </svg>
                      <ul
                        class="absolute right-0 p-1 border text-left text-[10px] w-48 z-20 bottom-full bg-white hidden group-hover:block"
                      >
                        <li>Yellow cards</li>
                      </ul>
                    </div>
                  </div>
                  <div class="flex-1 text-right">
                    <div class="group relative inline-block">
                      <img
                        class="w-3 inline-block relative left-[2px]"
                        src="@/assets/icons/card-percent.png"
                        title="Probability Booking"
                      />
                      <ul
                        class="absolute right-0 p-1 border text-left text-[10px] w-48 z-20 bottom-full bg-white hidden group-hover:block"
                      >
                        <li>Probability Booking</li>
                      </ul>
                    </div>
                  </div>
                  <div class="flex-1 text-right">
                    <div class="group relative inline-block">
                      <svg
                        class="inline-block"
                        width="8"
                        height="11"
                        viewBox="0 0 8 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M-3.8147e-06 1C-3.8147e-06 0.447715 0.447711 0 0.999996 0L7 0C7.55228 0 8 0.447715 8 1V10C8 10.5523 7.55228 11 7 11H0.999996C0.447711 11 -3.8147e-06 10.5523 -3.8147e-06 10L-3.8147e-06 1Z"
                          fill="#CF1818"
                        />
                      </svg>
                      <ul
                        class="absolute right-0 p-1 border text-left text-[10px] w-48 z-20 bottom-full bg-white hidden group-hover:block"
                      >
                        <li>Red cards</li>
                      </ul>
                    </div>
                  </div>
                  <div class="flex-1 text-right">
                    <div class="group relative inline-block">
                      <img
                        class="w-3 inline-block"
                        src="@/assets/icons/foul.png"
                      />
                      <ul
                        class="absolute right-0 p-1 border text-left text-[10px] w-48 z-20 bottom-full bg-white hidden group-hover:block"
                      >
                        <li>Average Fouls</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  class="text-xxs only-5-item"
                  v-for="(item, key) in popOverData.away_team.cards_fouls[getTimeFrameDropdown('cards_fouls')]"
                  :key="key"
                >
                  <p
                    class="flex"
                    v-if="
                      'injured_players' in popOverData.away_team &&
                      popOverData.away_team.injured_players.includes(
                        item.name
                      ) ||
                      popOverData.away_team.suspended_players.includes(
                        item.name
                      )
                    "
                  >
                    <span class="w-1/2 text-red-500"
                      >{{ item.name }}
                      <img
                        v-if="
                          popOverData.away_team.injured_players.includes(
                            item.name
                          )
                        "
                        class="w-3 inline-block relative left-[2px]"
                        src="@/assets/icons/red-cross.png"
                        title="Injured"
                      />
                      <img
                        v-if="
                          'suspended_players' in popOverData.away_team &&
                          popOverData.away_team.suspended_players.includes(
                            item.name
                          )
                        "
                        class="w-3 inline-block relative left-[2px]"
                        src="@/assets/icons/yellowred.png"
                        title="Suspended"
                      />
                    </span>
                    <span class="flex-1 text-right text-red-500">{{
                      item.avg_minutes
                    }}</span>
                    <span class="flex-1 text-right text-red-500">{{
                      item.yellow_cards_all_comps
                    }}</span>
                    <span class="flex-1 text-right text-red-500">-</span>
                    <span class="flex-1 text-right text-red-500">{{
                      item.red_cards_all_comps
                    }}</span>
                    <span class="flex-1 text-right text-red-500">{{
                      item.avg_fouls
                    }}</span>
                  </p>
                  <p class="flex" v-else>
                    <span class="w-1/2 text-gray-400">{{ item.name }}</span>
                    <span class="flex-1 text-right">{{
                      item.avg_minutes
                    }}</span>
                    <span class="flex-1 text-right">{{
                      item.yellow_cards_all_comps
                    }}</span>
                    <span
                      class="flex-1 text-right"
                      v-if="item.card_prediction"
                      >{{ item.card_prediction }}
                    </span>
                    <span class="flex-1 text-right" v-else>-</span>
                    <span class="flex-1 text-right">{{
                      item.red_cards_all_comps
                    }}</span>
                    <span class="flex-1 text-right">{{ item.avg_fouls }}</span>
                  </p>
                </div>
                <div class="flex items-center justify-center mt-4">
                  <button
                    v-on:click="toggleOnly5('cardsAwayShort')"
                    class="text-gray-400 underline text-xxs"
                  >
                    Show <template v-if="cardsAwayShort">more</template
                    ><template v-else>less</template>
                  </button>
                </div>
              </div>
              <div v-else class="flex items-start align-top space-x-4">
                <span
                  class="w-full align-bottom py-2 text-center text-gray-500 text-xs"
                >
                  No recent away team cards
                </span>
              </div>
            </CardOverlayItem>

            <div
              v-if="this.displayOdds"
              class="bottom p-2 bg-gray-200 -ml-4 -mr-4 flex"
            >
              <div class="flex-1">
                <div class="flex text-sm">
                  <p class="text-left w-1/4 overflow-hidden">best_odds</p>

                  <div
                    class="flex w-3/4 items-center justify-center flex-shrink-0"
                  >
                    <span
                      v-on:click="toggleItem('home_team')"
                      class="relative flex-1 text-center group underline"
                      :class="{
                        ' text-green-400': compareOdds(
                          decimalToFraction(match.home_team.best_odds),
                          decimalToFraction(match.home_team.fair_odds)
                        ),
                      }"
                    >
                      {{ convertOdds(match.home_team.best_odds) }}
                      <div
                        :class="{ '!block': itemShowing['home_team'] }"
                        class="absolute left-1/2 p-2 border text-xs w-48 z-20 bottom-full transform text-black -translate-x-1/2 bg-white hidden group-hover:block"
                      >
                        <span
                          v-for="(key, itemKey) in match.home_team.odds"
                          :key="`${itemKey}+home`"
                        >
                          <span v-if="key.bookie == match.home_team.bookmaker">
                            <span
                              v-if="
                                compareOdds(
                                  decimalToFraction(key.odds),
                                  decimalToFraction(match.home_team.fair_odds)
                                )
                              "
                              class="text-green-400 font-bold text-sm"
                            >
                              {{ key.bookie }} {{ convertOdds(key.odds) }}
                            </span>
                            <span v-else class="font-bold text-sm">
                              {{ key.bookie }} {{ convertOdds(key.odds) }}
                            </span>
                          </span>
                          <span v-else>
                            <span
                              v-if="
                                compareOdds(
                                  decimalToFraction(key.odds),
                                  decimalToFraction(match.home_team.fair_odds)
                                )
                              "
                              class="text-green-400"
                            >
                              {{ key.bookie }} {{ convertOdds(key.odds) }}
                            </span>
                            <span v-else>
                              {{ key.bookie }} {{ convertOdds(key.odds) }}
                            </span>
                          </span>
                          <br />
                        </span>
                      </div>
                    </span>
                    <span
                      v-on:click="toggleItem('draw')"
                      class="relative flex-1 text-center group underline"
                      :class="{
                        ' text-green-400': compareOdds(
                          decimalToFraction(match.draw.best_odds),
                          decimalToFraction(match.draw.fair_odds)
                        ),
                      }"
                    >
                      {{ convertOdds(match.draw.best_odds) }}
                      <div
                        :class="{ '!block': itemShowing['draw'] }"
                        class="absolute left-1/2 p-2 border text-xs w-48 z-20 bottom-full transform text-black -translate-x-1/2 bg-white hidden group-hover:block"
                      >
                        <span
                          v-for="(key, itemKey) in match.draw.odds"
                          :key="`${itemKey}+draw`"
                        >
                          <span v-if="key.bookie == match.draw.bookmaker">
                            <span
                              v-if="
                                compareOdds(
                                  decimalToFraction(key.odds),
                                  decimalToFraction(match.draw.fair_odds)
                                )
                              "
                              class="text-green-400 font-bold text-sm"
                            >
                              {{ key.bookie }} {{ convertOdds(key.odds) }}
                            </span>
                            <span v-else class="font-bold text-sm">
                              {{ key.bookie }} {{ convertOdds(key.odds) }}
                            </span>
                          </span>
                          <span v-else>
                            <span
                              v-if="
                                compareOdds(
                                  decimalToFraction(key.odds),
                                  decimalToFraction(match.draw.fair_odds)
                                )
                              "
                              class="text-green-400"
                            >
                              {{ key.bookie }} {{ convertOdds(key.odds) }}
                            </span>
                            <span v-else>
                              {{ key.bookie }} {{ convertOdds(key.odds) }}
                            </span>
                          </span>
                          <br />
                        </span>
                      </div>
                    </span>
                    <span
                      v-on:click="toggleItem('away_team')"
                      class="relative flex-1 text-center group underline"
                      :class="{
                        ' text-green-400': compareOdds(
                          decimalToFraction(match.away_team.best_odds),
                          decimalToFraction(match.away_team.fair_odds)
                        ),
                      }"
                    >
                      {{ convertOdds(match.away_team.best_odds) }}
                      <div
                        :class="{ '!block': itemShowing['away_team'] }"
                        class="absolute left-1/2 p-2 border text-xs w-48 z-20 bottom-full transform text-black -translate-x-1/2 bg-white hidden group-hover:block"
                      >
                        <span
                          v-for="(key, itemKey) in match.away_team.odds"
                          :key="`${itemKey}+away`"
                        >
                          <span v-if="key.bookie == match.away_team.bookmaker">
                            <span
                              v-if="
                                compareOdds(
                                  decimalToFraction(key.odds),
                                  decimalToFraction(match.away_team.fair_odds)
                                )
                              "
                              class="text-green-400 font-bold text-sm"
                            >
                              {{ key.bookie }} {{ convertOdds(key.odds) }}
                            </span>
                            <span v-else class="font-bold text-sm">
                              {{ key.bookie }} {{ convertOdds(key.odds) }}
                            </span>
                          </span>
                          <span v-else>
                            <span
                              v-if="
                                compareOdds(
                                  decimalToFraction(key.odds),
                                  decimalToFraction(match.away_team.fair_odds)
                                )
                              "
                              class="text-green-400"
                            >
                              {{ key.bookie }} {{ convertOdds(key.odds) }}
                            </span>
                            <span v-else>
                              {{ key.bookie }} {{ convertOdds(key.odds) }}
                            </span>
                          </span>
                          <br />
                        </span>
                      </div>
                    </span>
                  </div>
                  <!-- <div class="w-1/4 text-center relative">
                  <div class="group inline-block" v-on:click="toggleItem('tv')">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4 mx-auto pointer-events-none"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      :class="{ 'opacity-20': !match.tvstations.length }"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M3 5a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-2 2h-2.22l.123.489.804.804A1 1 0 0113 18H7a1 1 0 01-.707-1.707l.804-.804L7.22 15H5a2 2 0 01-2-2V5zm5.771 7H5V5h10v7H8.771z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <ul
                      v-if="match.tvstations.length"
                      class="absolute left-1/2 p-2 list-inside list-disc border text-left text-xs w-48 z-20 bottom-full transform -translate-x-1/2 bg-white hidden group-hover:block"
                    >
                      <li
                        v-for="(station, key) in match.tvstations"
                        :key="`${key}+tvstations`"
                      >
                        {{ station }}
                      </li>
                    </ul>
                    <ul
                      v-else
                      class="absolute left-1/2 p-2 list-inside list-disc border text-left text-xs w-48 z-20 bottom-full transform -translate-x-1/2 bg-white hidden group-hover:block"
                    >
                      <li>
                        Not televised.
                      </li>
                    </ul>
                  </div>
                </div> -->
                </div>
                <div class="flex text-sm">
                  <p class="text-left w-1/4 overflow-hidden">fair_odds</p>

                  <div
                    class="flex w-3/4 items-center justify-center flex-shrink-0"
                  >
                    <p class="flex-1 text-center">
                      {{ convertOdds(match.home_team.fair_odds) }}
                    </p>
                    <p class="flex-1 text-center">
                      {{ convertOdds(match.draw.fair_odds) }}
                    </p>
                    <p class="flex-1 text-center">
                      {{ convertOdds(match.away_team.fair_odds) }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-1/5 text-center relative">
                <div
                  class="group flex items-center justify-center h-full"
                  v-on:click="toggleItem('tv')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 mx-auto pointer-events-none"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    :class="{ 'opacity-20': !match.tvstations.length }"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3 5a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-2 2h-2.22l.123.489.804.804A1 1 0 0113 18H7a1 1 0 01-.707-1.707l.804-.804L7.22 15H5a2 2 0 01-2-2V5zm5.771 7H5V5h10v7H8.771z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <ul
                    v-if="match.tvstations.length"
                    class="absolute left-1/2 p-2 list-inside list-disc border text-left text-xs w-48 z-20 bottom-full transform -translate-x-1/2 bg-white hidden group-hover:block"
                  >
                    <li
                      v-for="(station, key) in match.tvstations"
                      :key="`${key}+tvstations`"
                    >
                      {{ station }}
                    </li>
                  </ul>
                  <ul
                    v-else
                    class="absolute left-1/2 p-2 list-inside list-disc border text-left text-xs w-48 z-20 bottom-full transform -translate-x-1/2 bg-white hidden group-hover:block"
                  >
                    <li>Not televised.</li>
                  </ul>
                </div>
              </div>
            </div>
          </template>
          <div v-else class="flex items-center justify-center py-10">
            <Loader />
          </div>
        </div>
      </div>
    </portal>
    <div v-if="extraBlurContent" class="extra-blur-content">
      <div
        class="bg-white border-black relative border p-4 pb-0 w-full mx-auto max-w-2xl"
      >
        <svg
          class="cursor-pointer absolute right-3 top-3 h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>

        <template v-if="gotOverlayData">
          <div class="flex-1 mb-4">
            <span class="text-gray-400 text-center w-full block text-sm">{{
              title
            }}</span>
            <div class="mt-2 flex items-center">
              <div class="w-1/4 text-center text-xs">
                <div
                  class="mx-auto w-10 h-10 mb-4 flex items-center justify-center"
                >
                  <img :src="match.home_team.logo" />
                </div>
                {{ match.home_team.name }}
                <p
                  class="text-center text-sm text-gray-400 mt-4"
                  v-if="popOverData.home_team.league_position != 'N/A'"
                >
                  {{ addNth(popOverData.home_team.league_position) }}
                </p>
                <div
                  class="flex items-center flex-row-reverse justify-center mt-2 gap-0.5"
                >
                  <span
                    v-for="(item, key) in recentForm('home_team').slice(0, 5)"
                    :key="key"
                    class="w-3 h-3 flex items-center justify-center text-white rounded-full"
                    :class="{
                      'bg-red-600': item == 'L',
                      'bg-gray-600 ': item == 'D',
                      'bg-green-600': item == 'W',
                    }"
                  >
                    <svg
                      v-if="item == 'W'"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-2 w-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>

                    <svg
                      v-if="item == 'L'"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-2 w-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>

                    <span
                      v-if="item == 'D'"
                      class="bg-white h-0.5 w-1.5 rounded-full"
                    ></span>
                  </span>
                </div>
              </div>
              <div class="w-2/4 px-4">
                <div class="flex mx-4 space-x-4 justify-center">
                  <div class="text-xs flex-1 text-gray-400 text-center">
                    home
                    <span
                      class="block mt-2 text-base"
                      :class="{
                        ' text-green-400': highestPrediction(
                          match.home_team.prediction
                        ),
                      }"
                      >{{ Math.round(match.home_team.prediction) }}%</span
                    >
                  </div>
                  <div class="text-xs flex-1 text-gray-400 text-center">
                    draw
                    <span
                      class="block mt-2 text-base"
                      :class="{
                        ' text-green-400': highestPrediction(
                          match.draw.prediction
                        ),
                      }"
                      >{{ Math.round(match.draw.prediction) }}%</span
                    >
                  </div>
                  <div
                    class="text-xs flex-1 relative text-gray-400 text-center"
                  >
                    away
                    <span
                      class="block mt-2 text-base"
                      :class="{
                        ' text-green-400': highestPrediction(
                          match.away_team.prediction
                        ),
                      }"
                      >{{ Math.round(match.away_team.prediction) }}%</span
                    >
                  </div>
                </div>
                <p class="uppercase text-center text-sm text-gray-500 mt-4">
                  {{ popOverData.competition }}
                </p>
                <p class="uppercase text-center text-xs text-gray-400 mt-2">
                  {{ getStage(match, popOverData) }}
                </p>
              </div>
              <div class="w-1/4 text-center text-xs">
                <div
                  class="mx-auto w-10 h-10 mb-4 flex items-center justify-center"
                >
                  <img :src="match.away_team.logo" />
                </div>
                {{ match.away_team.name }}
                <p
                  class="text-center text-sm text-gray-400 mt-4"
                  v-if="popOverData.away_team.league_position != 'N/A'"
                >
                  {{ addNth(popOverData.away_team.league_position) }}
                </p>
                <div
                  class="flex items-center flex-row-reverse justify-center mt-2 gap-0.5"
                >
                  <span
                    v-for="(item, key) in recentForm('away_team').slice(0, 5)"
                    :key="key"
                    class="w-3 h-3 flex items-center justify-center text-white rounded-full"
                    :class="{
                      'bg-red-600': item == 'L',
                      'bg-gray-600 ': item == 'D',
                      'bg-green-600': item == 'W',
                    }"
                  >
                    <svg
                      v-if="item == 'W'"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-2 w-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>

                    <svg
                      v-if="item == 'L'"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-2 w-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>

                    <span
                      v-if="item == 'D'"
                      class="bg-white h-0.5 w-1.5 rounded-full"
                    ></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <CardOverlayItem title="Match Summary">
              <div
                class="space-y-4 mt-3 px-6 flex flex-col md:w-[85%] mx-auto"
                v-if="
                  'summary' in popOverData && 'insights' in popOverData.summary && 'home_team' in popOverData.summary.insights && 'form' in popOverData.summary.insights.home_team
                " 
              >
              <h3 class="text-gray-500 uppercase text-center text-xs font-bold">
                  Match
                </h3>
                <span v-if="'news' in popOverData.summary.insights.match" class="text-xs">{{ popOverData.summary.insights.match.news }}</span>
                <ul v-else>
                  <li><span class="text-xs">&#8226;<span v-for="item in popOverData.summary.insights.match.headtohead"><strong v-if="popOverData.summary.insights.match.bold.includes(item)">{{ item }}</strong><span v-else>{{ item }}</span></span><br></span></li>
                  <li v-if="popOverData.summary.insights.match.homeheadtohead && popOverData.summary.insights.match.homeheadtohead.length > 0"><span class="text-xs">&#8226;<span v-for="item in popOverData.summary.insights.match.homeheadtohead"><strong v-if="popOverData.summary.insights.match.bold.includes(item)">{{ item }}</strong><span v-else>{{ item }}</span></span><br></span></li>
                </ul>
                <h3 class="text-gray-500 uppercase text-center text-xs font-bold">
                  {{ popOverData.home_team.name }}
                </h3>
                <span v-if="'news' in popOverData.summary.insights.home_team && popOverData.summary.insights.home_team.news.length > 0" class="text-xs">{{ popOverData.summary.insights.home_team.news }}</span>
                <ul v-else>
                  <li><span class="text-xs">&#8226;<span v-for="item in popOverData.summary.insights.home_team.form"><strong v-if="popOverData.summary.insights.home_team.bold.includes(item)">{{ item }}</strong><span v-else>{{ item }}</span></span><br></span></li>
                  <li><span class="text-xs">&#8226;<span v-for="item in popOverData.summary.insights.home_team.attackdefence"><strong v-if="popOverData.summary.insights.home_team.bold.includes(item)">{{ item }}</strong><span v-else>{{ item }}</span></span><br></span></li>
                  <li><span class="text-xs">&#8226;<span v-for="item in popOverData.summary.insights.home_team.goalscorer"><strong v-if="popOverData.summary.insights.home_team.bold.includes(item)">{{ item }}</strong><span v-else>{{ item }}</span></span><br></span></li>
                </ul>
                <h3 class="text-gray-500 uppercase text-center text-xs font-bold">
                  {{ popOverData.away_team.name }}
                </h3>
                <span v-if="'news' in popOverData.summary.insights.away_team && popOverData.summary.insights.away_team.news.length > 0" class="text-xs">{{ popOverData.summary.insights.away_team.news }}</span>
                <ul v-else>
                  <li><span class="text-xs">&#8226;<span v-for="item in popOverData.summary.insights.away_team.form"><strong v-if="popOverData.summary.insights.away_team.bold.includes(item)">{{ item }}</strong><span v-else>{{ item }}</span></span><br></span></li>
                  <li><span class="text-xs">&#8226;<span v-for="item in popOverData.summary.insights.away_team.attackdefence"><strong v-if="popOverData.summary.insights.away_team.bold.includes(item)">{{ item }}</strong><span v-else>{{ item }}</span></span><br></span></li>
                  <li><span class="text-xs">&#8226;<span v-for="item in popOverData.summary.insights.away_team.goalscorer"><strong v-if="popOverData.summary.insights.away_team.bold.includes(item)">{{ item }}</strong><span v-else>{{ item }}</span></span><br></span></li>
                </ul>
              </div>
              <div v-else class="flex items-start align-top space-x-4">
                <span
                  class="w-full align-bottom py-2 text-center text-gray-500 text-xs"
                >
                  No Match Summary found.
                </span>
              </div>
          </CardOverlayItem>          
          <CardOverlayItem title="Head to head">
            <div
              class="space-y-4 mt-3"
              v-if="
                popOverData.head_to_head &&
                popOverData.head_to_head.length > 0
              "
            >
              <div
                class="text-xs border-2 flex items-center border-gray-300 p-2 shadow"
                v-for="(item, key) in sliceItemLength('head_to_head')"
                :key="key"
              >
                <MatchEventListCard :matchEventDetails="item" />
              </div>
              <div class="flex items-center justify-center mt-4">
                <button
                  v-on:click="toggleSliceItem('head_to_head')"
                  class="text-gray-400 underline text-xxs"
                >
                  Show
                  <template v-if="sliceList.head_to_head <= 10">more</template
                  ><template v-else>less</template>
                </button>
              </div>
            </div>
            <div v-else class="flex items-start align-top space-x-4">
              <span
                class="w-full align-bottom py-2 text-center text-gray-500 text-xs"
              >
                No head to head matches found.
              </span>
            </div>
          </CardOverlayItem>

          <CardOverlayItem title="Recent Form">
              <div
                class="space-y-4 mt-3"
                v-if="
                  popOverData.home_team.recent_matches &&
                  popOverData.home_team.recent_matches.length > 0
                "
              >
                <h3
                  class="text-gray-500 uppercase text-center text-xs font-bold"
                >
                  {{ popOverData.home_team.name }}
                </h3>
                <div class="space-y-4 mt-3">
                  <div
                    class="text-xs border-2 flex items-center border-gray-300 p-2 shadow"
                    v-for="(item, key) in sliceItemLength(
                      'home_team',
                      'recent_matches'
                    )"
                    :class="
                      colorOfMatch(
                        recentForm('home_team'),
                        key,
                        popOverData.competition
                      )
                    "
                    :key="key"
                  >
                    <MatchEventListCard :matchEventDetails="item" />
                  </div>
                  <div class="flex items-center justify-center mt-4">
                    <button
                      v-on:click="toggleSliceItem('home_team')"
                      class="text-gray-400 underline text-xxs"
                    >
                      Show
                      <template v-if="sliceList.home_team <= 10">more</template
                      ><template v-else>less</template>
                    </button>
                  </div>
                </div>
              </div>
              <div v-else class="flex items-start align-top space-x-4">
                <span
                  class="w-full align-bottom py-2 text-center text-gray-500 text-xs"
                >
                  No recent home team matches found in {{ match.competition }}
                </span>
              </div>

              <div
                class="space-y-4 mt-3"
                v-if="
                  popOverData.away_team.recent_matches &&
                  popOverData.away_team.recent_matches.length > 0
                "
              >
                <h3
                  class="text-gray-500 uppercase text-center text-xs font-bold"
                >
                  {{ popOverData.away_team.name }}
                </h3>
                <div class="space-y-4 mt-3">
                  <div
                    class="text-xs border-2 flex items-center border-gray-300 p-2 shadow"
                    v-for="(item, key) in sliceItemLength(
                      'away_team',
                      'recent_matches'
                    )"
                    :class="
                      colorOfMatch(
                        recentForm('away_team'),
                        key,
                        popOverData.competition
                      )
                    "
                    :key="key"
                  >
                    <MatchEventListCard :matchEventDetails="item" />
                  </div>
                  <div class="flex items-center justify-center mt-4">
                    <button
                      v-on:click="toggleSliceItem('away_team')"
                      class="text-gray-400 underline text-xxs"
                    >
                      Show
                      <template v-if="sliceList.away_team <= 10">more</template
                      ><template v-else>less</template>
                    </button>
                  </div>
                </div>
              </div>
              <div v-else class="flex items-start align-top space-x-4">
                <span
                  class="w-full align-bottom py-2 text-center text-gray-500 text-xs"
                >
                  No recent away team matches found in {{ match.competition }}
                </span>
              </div>
            </CardOverlayItem>


          <!-- <CardOverlayItem
            title="Recent Cup Form"
            v-if="
              (popOverData.home_team.recent_cup_matches &&
                popOverData.home_team.recent_cup_matches.length > 0) ||
              (popOverData.away_team.recent_cup_matches &&
                popOverData.away_team.recent_cup_matches.length > 0)
            "
          >
            <div
              class="space-y-4 mt-3"
              v-if="
                popOverData.home_team.recent_cup_matches &&
                popOverData.home_team.recent_cup_matches.length > 0
              "
            >
              <h3 class="text-gray-500 uppercase text-center text-xs font-bold">
                {{ popOverData.home_team.name }}
              </h3>
              <div
                :class="
                  colorOfMatch(
                    recentCupForm('home_team'),
                    key,
                    popOverData.competition
                  )
                "
                class="text-xs border-2 flex items-center border-gray-300 p-2"
                v-for="(item, key) in popOverData.home_team.recent_cup_matches"
                :key="key"
              >
                <div class="flex-1">
                  <p>{{ item.home_team.name }}</p>
                  <p>{{ item.away_team.name }}</p>
                </div>
                <div class="flex items-center space-x-6">
                  <div>
                    <p>{{ item.result.split("-")[0] }}</p>
                    <p>{{ item.result.split("-")[1] }}</p>
                  </div>
                  <div class="text-center">
                    <small class="text-xxxs -mt-1 block text-gray-400">{{
                      item.date
                    }}</small>
                    <img
                      :src="item.competition_logo"
                      class="w-6 mx-auto mt-1"
                      :alt="item.competition"
                      :title="item.competition"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="flex items-start align-top space-x-4">
              <span
                class="w-full align-bottom py-2 text-center text-gray-500 text-xs"
              >
                No recent home team cup matches found in {{ match.competition }}
              </span>
            </div>

            <div
              class="space-y-4 mt-3"
              v-if="
                popOverData.away_team.recent_cup_matches &&
                popOverData.away_team.recent_cup_matches.length > 0
              "
            >
              <h3 class="text-gray-500 uppercase text-center text-xs font-bold">
                {{ popOverData.away_team.name }}
              </h3>
              <div
                :class="colorOfMatch(recentCupForm('away_team'), key)"
                class="text-xs border-2 flex items-center border-gray-300 p-2"
                v-for="(item, key) in popOverData.away_team.recent_cup_matches"
                :key="key"
              >
                <div class="flex-1">
                  <p>{{ item.home_team.name }}</p>
                  <p>{{ item.away_team.name }}</p>
                </div>
                <div class="flex items-center space-x-6">
                  <div>
                    <p>{{ item.result.split("-")[0] }}</p>
                    <p>{{ item.result.split("-")[1] }}</p>
                  </div>
                  <div class="text-center">
                    <small class="text-xxxs -mt-1 block text-gray-400">{{
                      item.date
                    }}</small>
                    <img
                      :src="item.competition_logo"
                      :alt="item.competition"
                      :title="item.competition"
                      class="w-6 mx-auto mt-1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="flex items-start align-top space-x-4">
              <span
                class="w-full align-bottom py-2 text-center text-gray-500 text-xs"
              >
                No recent away team cup matches found in {{ match.competition }}
              </span>
            </div>
          </CardOverlayItem> -->

          <CardOverlayItem title="Team Stats">
              <h3 class="text-gray-500 text-center text-xs font-bold p-1.5">
                All Competitions (Last 10 Games)
              </h3>
              <table class="w-full text-center text-xxs flex flex-col">
                <tr class="flex">
                  <td class="p-2 w-1/4">
                    <h3 class="text-gray-500 uppercase text-xs font-bold">
                      {{ popOverData.home_team.name }}
                    </h3>
                  </td>
                  <td class="p-2 flex-1"></td>
                  <td class="p-2 w-1/4">
                    <h3 class="text-gray-500 uppercase text-xs font-bold">
                      {{ popOverData.away_team.name }}
                    </h3>
                  </td>
                </tr>
                <tr class="flex">
                  <div
                    class="flex items-center flex-row-reverse justify-center mt-2 gap-0.5"
                  >
                    <span
                      v-for="(item, key) in recentHomeForm('home_team').slice(
                        0,
                        5
                      )"
                      :key="key"
                      class="w-3 h-3 flex items-center justify-center text-white rounded-full"
                      :class="{
                        'bg-red-600': item == 'L',
                        'bg-gray-600 ': item == 'D',
                        'bg-green-600': item == 'W',
                      }"
                    >
                      <svg
                        v-if="item == 'W'"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-2 w-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>

                      <svg
                        v-if="item == 'L'"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-2 w-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>

                      <span
                        v-if="item == 'D'"
                        class="bg-white h-0.5 w-1.5 rounded-full"
                      ></span>
                    </span>
                  </div>
                  <td class="p-2 flex-1">Home Form</td>
                  <div
                    class="flex items-center flex-row-reverse justify-center mt-2 gap-0.5"
                  >
                    <span
                      v-for="(item, key) in recentHomeForm('away_team').slice(
                        0,
                        5
                      )"
                      :key="key"
                      class="w-3 h-3 flex items-center justify-center text-white rounded-full"
                      :class="{
                        'bg-red-600': item == 'L',
                        'bg-gray-600 ': item == 'D',
                        'bg-green-600': item == 'W',
                      }"
                    >
                      <svg
                        v-if="item == 'W'"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-2 w-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>

                      <svg
                        v-if="item == 'L'"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-2 w-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>

                      <span
                        v-if="item == 'D'"
                        class="bg-white h-0.5 w-1.5 rounded-full"
                      ></span>
                    </span>
                  </div>
                </tr>
                <tr class="flex">
                  <div
                    class="flex items-center flex-row-reverse justify-center mt-2 gap-0.5"
                  >
                    <span
                      v-for="(item, key) in recentAwayForm('home_team').slice(
                        0,
                        5
                      )"
                      :key="key"
                      class="w-3 h-3 flex items-center justify-center text-white rounded-full"
                      :class="{
                        'bg-red-600': item == 'L',
                        'bg-gray-600 ': item == 'D',
                        'bg-green-600': item == 'W',
                      }"
                    >
                      <svg
                        v-if="item == 'W'"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-2 w-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>

                      <svg
                        v-if="item == 'L'"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-2 w-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>

                      <span
                        v-if="item == 'D'"
                        class="bg-white h-0.5 w-1.5 rounded-full"
                      ></span>
                    </span>
                  </div>
                  <td class="p-2 flex-1">Away Form</td>
                  <div
                    class="flex items-center flex-row-reverse justify-center mt-2 gap-0.5"
                  >
                    <span
                      v-for="(item, key) in recentAwayForm('away_team').slice(
                        0,
                        5
                      )"
                      :key="key"
                      class="w-3 h-3 flex items-center justify-center text-white rounded-full"
                      :class="{
                        'bg-red-600': item == 'L',
                        'bg-gray-600 ': item == 'D',
                        'bg-green-600': item == 'W',
                      }"
                    >
                      <svg
                        v-if="item == 'W'"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-2 w-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>

                      <svg
                        v-if="item == 'L'"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-2 w-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>

                      <span
                        v-if="item == 'D'"
                        class="bg-white h-0.5 w-1.5 rounded-full"
                      ></span>
                    </span>
                  </div>
                </tr>
                <tr
                  class="relative overflow-hidden bg-white flex"
                  v-if="
                    popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].pct_clean_sheets &&
                    popOverData.away_team.stats.pct_clean_sheets
                  "
                >
                  <td :class="`relative z-10 p-2`" >
                    {{
                      popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].pct_clean_sheets
                        ? popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].pct_clean_sheets
                        : ""
                    }}
                  </td>
                  <td :class="`relative z-10 p-2 flex-1`">Clean Sheets</td>
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.away_team.stats.pct_clean_sheets
                        ? popOverData.away_team.stats.pct_clean_sheets
                        : ""
                    }}
                  </td>
                  <div
                    v-if="
                      popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].pct_clean_sheets &&
                      popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].pct_clean_sheets
                    "
                    class="absolute inset-1.5 inset-x-[5%] flex"
                  >
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].pct_clean_sheets,
                            popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].pct_clean_sheets
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].pct_clean_sheets,
                            popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].pct_clean_sheets
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </tr>
                <tr class="relative overflow-hidden bg-white flex">
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].failed_to_score.total
                        ? popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].failed_to_score.total
                        : ""
                    }}
                  </td>
                  <td :class="`relative z-10 p-2 flex-1`">Failed to Score</td>
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.away_team.stats.failed_to_score.total
                        ? popOverData.away_team.stats.failed_to_score.total
                        : ""
                    }}
                  </td>
                  <div
                    v-if="
                      popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].failed_to_score &&
                      popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].failed_to_score
                    "
                    class="absolute inset-1.5 inset-x-[5%] flex"
                  >
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].failed_to_score,
                            popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].failed_to_score
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].failed_to_score,
                            popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].failed_to_score
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </tr>
                <tr class="relative overflow-hidden bg-white flex">
                  <td :class="`w-1/4 relative z-10 p-2`">
                    {{
                      popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].average_goals.F
                        ? popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].average_goals.F.total
                        : 0
                    }}
                  </td>
                  <td :class="`flex-1 relative z-10 p-2`">Avg Goals For</td>
                  <td :class="`w-1/4 relative z-10 p-2`">
                    {{
                      popOverData.away_team.stats.average_goals.F
                        ? popOverData.away_team.stats.average_goals.F.total
                        : 0
                    }}
                  </td>
                  <div
                    class="absolute inset-1.5 inset-x-[5%] flex"
                    v-if="
                      popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].average_goals_for &&
                      popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].average_goals_for
                    "
                  >
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts
                              .average_goals_for,
                            popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].average_goals_for
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts
                              .average_goals_for,
                            popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].average_goals_for
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </tr>
                <tr class="relative overflow-hidden bg-white flex">
                  <td :class="`w-1/4 relative z-10 p-2`">
                    {{
                      popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].average_goals.A
                        ? popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].average_goals.A.total
                        : 0
                    }}
                  </td>
                  <td :class="`flex-1 relative z-10 p-2`">Avg Goals Against</td>
                  <td :class="`w-1/4 relative z-10 p-2`">
                    {{
                      popOverData.away_team.stats.average_goals.A
                        ? popOverData.away_team.stats.average_goals.A.total
                        : 0
                    }}
                  </td>
                  <div
                    class="absolute inset-1.5 inset-x-[5%] flex"
                    v-if="
                      popOverData.home_team.stats_charts
                        .average_goals_against &&
                      popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].average_goals_against
                    "
                  >
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts
                              .average_goals_against,
                            popOverData.away_team.stats_charts
                              .average_goals_against
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts
                              .average_goals_against,
                            popOverData.home_team.stats_charts
                              .average_goals_against
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </tr>
                <tr
                  class="flex relative"
                  v-if="
                    (popOverData.home_team.stats.average_XG.F &&
                      popOverData.home_team.stats.average_XG.F > 0) ||
                    (popOverData.away_team.stats.average_XG.F &&
                      popOverData.away_team.stats.average_XG.F > 0)
                  "
                >
                  <td :class="`w-1/4 relative z-10 p-2`">
                    {{
                      popOverData.home_team.stats.average_XG.F
                        ? popOverData.home_team.stats.average_XG.F
                        : ""
                    }}
                  </td>
                  <td :class="`relative z-10 p-2 flex-1`">Avg. xG For</td>
                  <td :class="`w-1/4 relative z-10 p-2`">
                    {{
                      popOverData.away_team.stats.average_XG.F
                        ? popOverData.away_team.stats.average_XG.F
                        : ""
                    }}
                  </td>
                  <div
                    class="absolute inset-1.5 inset-x-[5%] flex"
                    v-if="
                      popOverData.home_team.stats_charts.average_xg_for &&
                      popOverData.away_team.stats_charts.average_xg_for
                    "
                  >
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts.average_xg_for,
                            popOverData.away_team.stats_charts.average_xg_for
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts.average_xg_for,
                            popOverData.home_team.stats_charts.average_xg_for
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </tr>
                <tr
                  class="flex relative"
                  v-if="
                    (popOverData.home_team.stats.average_XG.A &&
                      popOverData.home_team.stats.average_XG.A > 0) ||
                    (popOverData.away_team.stats.average_XG.A &&
                      popOverData.away_team.stats.average_XG.A > 0)
                  "
                >
                  <td :class="`w-1/4 relative z-10 p-2`">
                    {{
                      popOverData.home_team.stats.average_XG.A
                        ? popOverData.home_team.stats.average_XG.A
                        : ""
                    }}
                  </td>
                  <td :class="`relative z-10 p-2 flex-1`">Avg. xG Against</td>
                  <td :class="`w-1/4 relative z-10 p-2`">
                    {{
                      popOverData.away_team.stats.average_XG.A
                        ? popOverData.away_team.stats.average_XG.A
                        : ""
                    }}
                  </td>
                  <div
                    class="absolute inset-1.5 inset-x-[5%] flex"
                    v-if="
                      popOverData.home_team.stats_charts.average_xg_against &&
                      popOverData.away_team.stats_charts.average_xg_against
                    "
                  >
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts
                              .average_xg_against,
                            popOverData.away_team.stats_charts
                              .average_xg_against
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts
                              .average_xg_against,
                            popOverData.home_team.stats_charts
                              .average_xg_against
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </tr>
                <tr class="relative overflow-hidden bg-white flex" 
                v-if="
                    (popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_shots_per_game &&
                      popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_shots_per_game > 0) ||
                    (popOverData.away_team.stats.avg_shots_per_game &&
                      popOverData.away_team.stats.avg_shots_per_game > 0)
                  "
                >
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_shots_per_game
                        ? popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_shots_per_game
                        : 0
                    }}
                  </td>
                  <td :class="`flex-1 relative z-10 p-2`">Avg Shots</td>
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.away_team.stats.avg_shots_per_game
                        ? popOverData.away_team.stats.avg_shots_per_game
                        : 0
                    }}
                  </td>
                  <div
                    class="absolute inset-1.5 inset-x-[5%] flex"
                    v-if="
                      popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].avg_shots_per_game &&
                      popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].avg_shots_per_game
                    "
                  >
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts
                              .avg_shots_per_game,
                            popOverData.away_team.stats_charts
                              .avg_shots_per_game
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts
                              .avg_shots_per_game,
                            popOverData.home_team.stats_charts
                              .avg_shots_per_game
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </tr>
                <tr class="relative overflow-hidden bg-white flex"
                v-if="
                    (popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_shots_on_target_per_game &&
                      popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_shots_on_target_per_game > 0) ||
                    (popOverData.away_team.stats.avg_shots_on_target_per_game &&
                      popOverData.away_team.stats.avg_shots_on_target_per_game > 0)
                  "
                >
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_shots_on_target_per_game
                        ? popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_shots_on_target_per_game
                        : 0
                    }}
                  </td>
                  <td :class="`flex-1 relative z-10 p-2`">Avg Shots On Target</td>
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.away_team.stats.avg_shots_on_target_per_game
                        ? popOverData.away_team.stats.avg_shots_on_target_per_game
                        : 0
                    }}
                  </td>
                  <div
                    class="absolute inset-1.5 inset-x-[5%] flex"
                    v-if="
                      popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].avg_shots_on_target_per_game &&
                      popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].avg_shots_on_target_per_game
                    "
                  >
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts
                              .avg_shots_on_target_per_game,
                            popOverData.away_team.stats_charts
                              .avg_shots_on_target_per_game
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts
                              .avg_shots_on_target_per_game,
                            popOverData.home_team.stats_charts
                              .avg_shots_on_target_per_game
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </tr>
                <tr v-if="(popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_corners_per_game && popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_corners_per_game > 0) || (popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_corners_per_game && popOverData.away_team.stats.avg_corners_per_game > 0)" class="relative overflow-hidden bg-white flex">
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_corners_per_game
                        ? popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_corners_per_game
                        : 0
                    }}
                  </td>
                  <td :class="`flex-1 relative z-10 p-2`">Avg Corners</td>
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.away_team.stats.avg_corners_per_game
                        ? popOverData.away_team.stats.avg_corners_per_game
                        : 0
                    }}
                  </td>
                  <div
                    class="absolute inset-1.5 inset-x-[5%] flex"
                    v-if="
                      popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].avg_corners_per_game &&
                      popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].avg_corners_per_game
                    "
                  >
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts
                              .avg_corners_per_game,
                            popOverData.away_team.stats_charts
                              .avg_corners_per_game
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts
                              .avg_corners_per_game,
                            popOverData.home_team.stats_charts
                              .avg_corners_per_game
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </tr>
                <tr class="relative overflow-hidden bg-white flex">
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_booking_points
                        ? popOverData.home_team.stats[getTimeFrameDropdown('teamstats')].avg_booking_points
                        : 0
                    }}
                  </td>
                  <td :class="`flex-1 relative z-10 p-2`">
                    Avg Booking Points
                  </td>
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.away_team.stats.avg_booking_points
                        ? popOverData.away_team.stats.avg_booking_points
                        : 0
                    }}
                  </td>
                  <div
                    class="absolute inset-1.5 inset-x-[5%] flex"
                    v-if="
                      popOverData.home_team.stats_charts[getTimeFrameDropdown('teamstats')].avg_booking_points &&
                      popOverData.away_team.stats_charts[getTimeFrameDropdown('teamstats')].avg_booking_points
                    "
                  >
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts
                              .avg_booking_points,
                            popOverData.away_team.stats_charts
                              .avg_booking_points
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts
                              .avg_booking_points,
                            popOverData.home_team.stats_charts
                              .avg_booking_points
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </tr>
                <!-- <tr
                  class="flex relative"
                  v-if="
                    (popOverData.home_team.team_ranking &&
                      popOverData.home_team.team_ranking > 0) ||
                    (popOverData.away_team.team_ranking &&
                      popOverData.away_team.team_ranking > 0)
                  "
                >
                  <td :class="`relative z-10 p-2`">
                    {{ addNth(popOverData.home_team.team_ranking) }}
                  </td>
                  <td :class="`relative z-10 p-2 flex-1`">
                    Club World Ranking
                  </td>
                  <td :class="`relative z-10 p-2`">
                    {{ addNth(popOverData.away_team.team_ranking) }}
                  </td>
                  <div
                    class="absolute inset-1.5 inset-x-[5%] flex"
                    v-if="
                      popOverData.home_team.stats_charts.team_ranking &&
                      popOverData.away_team.stats_charts.team_ranking
                    "
                  >
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts.team_ranking,
                            popOverData.away_team.stats_charts.team_ranking
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts.team_ranking,
                            popOverData.home_team.stats_charts.team_ranking
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </tr> -->
                <tr
                  class="flex relative"
                  v-if="
                    (popOverData.home_team.fifa_world_ranking &&
                      popOverData.home_team.fifa_world_ranking > 0) ||
                    (popOverData.away_team.fifa_world_ranking &&
                      popOverData.away_team.fifa_world_ranking > 0)
                  "
                >
                  <td :class="`relative z-10 p-2`">
                    {{ addNth(popOverData.home_team.fifa_world_ranking) }}
                  </td>
                  <td :class="`relative z-10 p-2 flex-1`">
                    FIFA World Ranking
                  </td>
                  <td :class="`relative z-10 p-2`">
                    {{ addNth(popOverData.away_team.fifa_world_ranking) }}
                  </td>
                  <div
                    class="absolute inset-1.5 inset-x-[5%] flex"
                    v-if="
                      popOverData.home_team.stats_charts.fifa_world_ranking &&
                      popOverData.away_team.stats_charts.fifa_world_ranking
                    "
                  >
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts.fifa_world_ranking,
                            popOverData.away_team.stats_charts.fifa_world_ranking
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts.fifa_world_ranking,
                            popOverData.home_team.stats_charts.fifa_world_ranking
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </tr>
                <tr
                  class="flex relative"
                  v-if="
                    popOverData.home_team.market_value &&
                    popOverData.away_team.market_value
                  "
                >
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.home_team.market_value
                        ? popOverData.home_team.market_value
                        : ""
                    }}
                  </td>
                  <td :class="`flex-1 relative z-10 p-2`">
                    Squad Market Value
                  </td>
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.away_team.market_value
                        ? popOverData.away_team.market_value
                        : 0
                    }}
                  </td>
                  <div
                    class="absolute inset-1.5 inset-x-[5%] flex"
                    v-if="
                      popOverData.home_team.stats_charts.market_value &&
                      popOverData.away_team.stats_charts.market_value
                    "
                  >
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts.market_value,
                            popOverData.away_team.stats_charts.market_value
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts.market_value,
                            popOverData.home_team.stats_charts.market_value
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </tr>
                <tr
                  class="flex relative"
                  v-if="
                    (popOverData.home_team.market_value_league_rank &&
                      popOverData.home_team.market_value_league_rank > 0) &&
                    (popOverData.away_team.market_value_league_rank &&
                      popOverData.away_team.market_value_league_rank > 0)
                  "
                >
                  <td :class="`relative z-10 p-2`">
                    {{ addNth(popOverData.home_team.market_value_league_rank) }}
                  </td>
                  <td class="flex-1 p-2 relative z-10">
                    Squad Market Value League Ranking
                  </td>
                  <td :class="`relative z-10 p-2`">
                    {{ addNth(popOverData.away_team.market_value_league_rank) }}
                  </td>
                  <!-- <div class="absolute inset-1.5 inset-x-[5%] flex">
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts.market_value
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts.market_value
                          )
                        "
                      ></div>
                    </div>
                  </div> -->
                </tr>
                <!-- <tr class="flex relative overflow-hidden bg-white">
                  <td :class="`relative z-10 p-2`">
                    {{
                      popOverData.home_team.stats.average_first_goal_scored.home
                        ? popOverData.home_team.stats.average_first_goal_scored
                            .home
                        : 0
                    }}
                  </td>
                  <td class="p-2 relative z-10 flex-1">
                    Avg Time First Goal Scored
                  </td>
                  <td :class="`relative z-10 p-2 `">
                    {{
                      popOverData.away_team.stats.average_first_goal_scored.away
                        ? popOverData.away_team.stats.average_first_goal_scored
                            .away
                        : 0
                    }}
                  </td> -->

                <!-- </tr> -->
                <!-- <tr class="flex relative overflow-hidden bg-white">
                  <td :class="`relative z-10 p-2 `">
                    {{
                      popOverData.home_team.stats.average_first_goal_conceded
                        .home
                        ? popOverData.home_team.stats
                            .average_first_goal_conceded.home
                        : 0
                    }}
                  </td>
                  <td class="flex-1 p-2 relative z-10">
                    Avg Time First Goal Conceded
                  </td>
                  <td :class="`relative z-10 p-2 `">
                    {{
                      popOverData.away_team.stats.average_first_goal_conceded
                        .away
                        ? popOverData.away_team.stats
                            .average_first_goal_conceded.away
                        : 0
                    }}
                  </td> -->
                  <!-- <div class="absolute inset-1.5 inset-x-[5%] flex">
                    <div class="w-1/2 flex items-center justify-end">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.home_team.stats_charts
                              .average_first_goal_conceded
                          )
                        "
                      ></div>
                    </div>
                    <div class="w-1/2 flex items-center justify-start">
                      <div
                        class="h-full"
                        :style="
                          generateBackgroundBar(
                            popOverData.away_team.stats_charts
                              .average_first_goal_conceded
                          )
                        "
                      ></div>
                    </div>
                  </div> -->
                <!-- </tr> -->
              </table>
            </CardOverlayItem>


          <CardOverlayItem title="Goal Scorers" class="">
            <h3 class="text-gray-500 text-center text-xs font-bold p-1.5">
              All Competitions (Last 10 Games)
            </h3>
            <div
              class="space-y-2 mt-3"
              :class="{ 'only-5-toggle': goalScorersHomeShort }"
              v-if="
                popOverData.home_team.top_scorers &&
                popOverData.home_team.top_scorers.length > 0
              "
            >
              <div class="flex">
                <h3 class="w-1/2 uppercase text-xs text-gray-500 font-bold">
                  {{ popOverData.home_team.player_short_name }}
                </h3>
                <div class="flex-1 text-right">
                  <img
                    class="w-3 inline-block relative left-[2px]"
                    src="@/assets/icons/goal-icon.png"
                    title="Goals"
                  />
                </div>
                <div class="flex-1 text-right">
                  <img
                    class="w-3 inline-block"
                    src="@/assets/icons/assist.png"
                    title="Assists"
                  />
                </div>
                <div class="flex-1 text-right">
                  <img
                    class="w-3 inline-block"
                    src="@/assets/icons/shots.jpg"
                    title="Total Shots"
                  />
                </div>
                <div class="flex-1 text-right">
                  <img
                    class="w-3 inline-block"
                    src="@/assets/icons/target.png"
                    title="Shots on Target"
                  />
                </div>
              </div>
              <div
                class="text-xxs only-5-item"
                v-for="(item, key) in popOverData.home_team.top_scorers"
                :key="key"
              >
                <p
                  class="flex"
                  v-if="
                    'injured_players' in popOverData.home_team &&
                    'suspended_players' in popOverData.home_team &&
                    popOverData.home_team.injured_players.includes(item.player_short_name) ||
                    popOverData.home_team.suspended_players.includes(item.player_short_name)
                  "
                >
                  <span class="w-2/5 text-red-500"
                    >{{ item.player_short_name }}
                    <img
                      v-if="
                        popOverData.home_team.injured_players.includes(
                          item.player_short_name
                        )
                      "
                      class="w-3 inline-block relative left-[2px]"
                      src="@/assets/icons/red-cross.png"
                      title="Injured"
                    />
                    <img
                      v-if="
                        popOverData.home_team.suspended_players.includes(
                          item.player_short_name
                        )
                      "
                      class="w-3 inline-block relative left-[2px]"
                      src="@/assets/icons/yellowred.png"
                      title="Suspended"
                    />
                  </span>
                  <span class="flex-1 text-right text-red-500">{{
                    item.avg_minutes
                  }}</span>
                  <span class="flex-1 text-right text-red-500">{{
                    item.goals_all_comps
                  }}</span>
                  <span class="flex-1 text-right text-red-500">-</span>
                  <span class="flex-1 text-right pr-[2px] text-red-500">{{
                    item.assists
                  }}</span>
                  <span class="flex-1 text-right text-red-500">{{
                    Math.round(item.avg_shots_total * 10) / 10
                  }}</span>
                  <span class="flex-1 text-right text-red-500">{{
                    Math.round(item.avg_shots_on_target * 10) / 10
                  }}</span>
                </p>
                <p class="flex" v-else>
                  <span class="w-2/5 text-gray-400">{{ item.player_short_name }}</span>
                  <span class="flex-1 text-right">{{ item.avg_minutes }}</span>
                  <span class="flex-1 text-right">{{
                    item.goals_all_comps
                  }}</span>
                  <span class="flex-1 text-right" v-if="item.goal_prediction">{{
                    item.goal_prediction
                  }}</span>
                  <span class="flex-1 text-right" v-else>-</span>
                  <span class="flex-1 text-right pr-[2px]">{{
                    item.assists
                  }}</span>
                  <span class="flex-1 text-right">{{
                    Math.round(item.avg_shots_total * 10) / 10
                  }}</span>
                  <span class="flex-1 text-right">{{
                    Math.round(item.avg_shots_on_target * 10) / 10
                  }}</span>
                </p>
              </div>
              <div class="flex items-center justify-center mt-4">
                <button
                  v-on:click="toggleOnly5('goalScorersHomeShort')"
                  class="text-gray-400 underline text-xxs"
                >
                  Show <template v-if="goalScorersHomeShort">more</template
                  ><template v-else>less</template>
                </button>
              </div>
            </div>
            <div v-else class="flex items-start align-top space-x-4">
              <span
                class="w-full align-bottom py-2 text-center text-gray-500 text-xs"
              >
                No recent home team goal scorers
              </span>
            </div>

            <div
              class="space-y-2 mt-10"
              v-if="
                popOverData.away_team.top_scorers &&
                popOverData.away_team.top_scorers.length > 0
              "
              :class="{ 'only-5-toggle': goalScorersAwayShort }"
            >
              <div class="flex">
                <h3 class="w-1/2 uppercase text-xs text-gray-500 font-bold">
                  {{ popOverData.away_team.name }}
                </h3>
                <div class="flex-1 text-right">
                  <img
                    class="w-3 inline-block relative left-[2px]"
                    src="@/assets/icons/goal-icon.png"
                    title="Goals"
                  />
                </div>
                <div class="flex-1 text-right">
                  <img
                    class="w-5 inline-block relative left-[2px]"
                    src="@/assets/icons/goal-percent2.png"
                    title="Percentage to Score"
                  />
                  <ul
                    class="absolute right-0 p-2 list-inside list-disc border text-left text-xs w-48 z-20 bottom-full bg-white hidden group-hover:block"
                  >
                    <li>Goal Prediction</li>
                  </ul>
                </div>
                <div class="flex-1 text-right">
                  <img
                    class="w-3 inline-block relative"
                    src="@/assets/icons/assist.png"
                    title="Assists"
                  />
                  <div class="flex-1 text-right">
                    <div class="group relative inline-block">
                      <img
                        class="w-3 inline-block"
                        src="@/assets/icons/shots.jpg"
                        title="Total Shots"
                      />
                      <ul
                        class="absolute right-0 p-2 list-inside list-disc border text-left text-xs w-48 z-20 bottom-full bg-white hidden group-hover:block"
                      >
                        <li>Avg Total Shots</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="flex-1 text-right">
                  <img
                    class="w-3 inline-block"
                    src="@/assets/icons/target.png"
                    title="Shots on Target"
                  />
                </div>
              </div>
              <div
                class="text-xxs only-5-item"
                v-for="(item, key) in popOverData.away_team.top_scorers"
                :key="key"
              >
                <p
                  class="flex"
                  v-if="
                    'injured_players' in popOverData.away_team &&
                    'suspended_players' in popOverData.away_team &&
                    popOverData.away_team.injured_players.includes(item.name) ||
                    popOverData.away_team.suspended_players.includes(item.name)
                  "
                >
                  <span class="w-2/5 text-red-500"
                    >{{ item.name }}
                    <img
                      v-if="
                        popOverData.away_team.injured_players.includes(
                          item.name
                        )
                      "
                      class="w-3 inline-block relative left-[2px]"
                      src="@/assets/icons/red-cross.png"
                      title="Injured"
                    />
                    <img
                      v-if="
                        popOverData.away_team.suspended_players.includes(
                          item.name
                        )
                      "
                      class="w-3 inline-block relative left-[2px]"
                      src="@/assets/icons/yellowred.png"
                      title="Suspended"
                    />
                  </span>

                  <span class="flex-1 text-right text-red-500">{{
                    item.avg_minutes
                  }}</span>

                  <span class="flex-1 text-right text-red-500">{{
                    item.goals_all_comps
                  }}</span>
                  <span class="flex-1 text-right text-red-500">-</span>
                  <span class="flex-1 text-right pr-[2px] text-red-500">{{
                    item.assists
                  }}</span>
                  <span class="flex-1 text-right text-red-500">{{
                    Math.round(item.avg_shots_total * 10) / 10
                  }}</span>
                  <span class="flex-1 text-right text-red-500">{{
                    Math.round(item.avg_shots_on_target * 10) / 10
                  }}</span>
                </p>
                <p class="flex" v-else>
                  <span class="w-2/5 text-gray-400">{{ item.name }}</span>
                  <span class="flex-1 text-right">{{ item.avg_minutes }}</span>
                  <span class="flex-1 text-right">{{
                    item.goals_all_comps
                  }}</span>
                  <span class="flex-1 text-right" v-if="item.goal_prediction">{{
                    item.goal_prediction
                  }}</span>
                  <span class="flex-1 text-right" v-else>-</span>
                  <span class="flex-1 text-right pr-[2px]">{{
                    item.assists
                  }}</span>
                  <span class="flex-1 text-right">{{
                    Math.round(item.avg_shots_total * 10) / 10
                  }}</span>
                  <span class="flex-1 text-right">{{
                    Math.round(item.avg_shots_on_target * 10) / 10
                  }}</span>
                </p>
              </div>
              <div class="flex items-center justify-center mt-4">
                <button
                  v-on:click="toggleOnly5('goalScorersAwayShort')"
                  class="text-gray-400 underline text-xxs"
                >
                  Show <template v-if="goalScorersAwayShort">more</template
                  ><template v-else>less</template>
                </button>
              </div>
            </div>
            <div v-else class="flex items-start align-top space-x-4">
              <span
                class="w-full align-bottom py-2 text-center text-gray-500 text-xs"
              >
                No recent away team goal scorers
              </span>
            </div>
          </CardOverlayItem>

          <CardOverlayItem title="Match Predictions">
              <h3 class="text-gray-500 text-center text-xs font-bold p-1.5">
                Team
              </h3>

              <table class="w-full text-center text-xs">
                <tr>
                  <td class="p-2 w-1/4">
                    <h3 class="text-gray-500 uppercase text-xs font-bold">
                      {{ popOverData.home_team.name }}
                    </h3>
                  </td>
                  <td class="p-2"></td>
                  <td class="p-2 w-1/4">
                    <h3 class="text-gray-500 uppercase text-xs font-bold">
                      {{ popOverData.away_team.name }}
                    </h3>
                  </td>
                </tr>
                <tr>
                  <td class="text-center p-2 w-1/4">
                    {{ Math.round(match.predictions.HT_clean_sheet) }}%
                  </td>
                  <td class="text-gray-400 p-2 flex-1">Clean Sheet</td>
                  <td class="text-center p-2">
                    {{ Math.round(match.predictions.AT_clean_sheet) }}%
                  </td>
                </tr>
                <tr>
                  <td class="text-center p-2 w-1/4">
                    {{ Math.round(match.predictions.HT_over_0_5) }}%
                  </td>
                  <td class="text-gray-400 p-2 flex-1">
                    Over 0.5 Goals Scored
                  </td>
                  <td class="text-center p-2">
                    {{ Math.round(match.predictions.AT_over_0_5) }}%
                  </td>
                </tr>
                <tr>
                  <td class="text-center p-2">
                    {{ Math.round(match.predictions.HT_over_1_5) }}%
                  </td>
                  <td class="text-gray-400 p-2 flex-1">
                    Over 1.5 Goals Scored
                  </td>
                  <td class="text-center p-2">
                    {{ Math.round(match.predictions.AT_over_1_5) }}%
                  </td>
                </tr>
                <!-- <tr>
                  <td class="text-center p-2">
                    {{ toScoreFirstHalf(match, popOverData, "home_team") }}%
                  </td>
                  <td class="text-gray-400 p-2 flex-1">To Score First Half</td>
                  <td class="text-center  p-2">
                    {{ toScoreFirstHalf(match, popOverData, "away_team") }}%
                  </td>
                </tr> -->
                <tr>
                  <td></td>
                  <td>
                    <h3
                      class="text-gray-500 text-center text-xs font-bold p-1.5 pt-3"
                    >
                      Match
                    </h3>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td class="text-gray-400 p-2 flex-1" colspan="3">
                    Both Teams to Score:
                    <span class="text-black"
                      >{{ Math.round(match.predictions.btts) }}%</span
                    >
                  </td>
                </tr>
                <tr>
                  <td class="text-gray-400 p-2 flex-1" colspan="3">
                    Most Likely Scorelines:<br />
                    <span
                      class="text-black"
                      v-for="(item, key) in match.predictions
                        .most_likely_score_lines"
                      :key="key"
                    >
                      <span class="text-brandGrey">{{ item.score }}</span>
                      {{ item.value }}<br />
                    </span>
                  </td>
                </tr>
                <!-- <tr>
                  <td class="text-gray-400 p-2 flex-1" colspan="3">
                    Goal In The First Half:
                    <span class="text-black"
                      >{{
                        toScoreFirstHalf(match, popOverData, "both_teams")
                      }}%</span
                    >
                  </td>
                </tr> -->
                <tr>
                  <td class="text-gray-400 p-2 flex-1" colspan="3">
                    Over 2.5 Goals:
                    <span class="text-black"
                      >{{ Math.round(match.predictions.FT_over_2_5) }}%</span
                    >
                  </td>
                </tr>
                <tr>
                  <td class="text-gray-400 p-2 flex-1" colspan="3">
                    Over 3.5 Goals:
                    <span class="text-black"
                      >{{ Math.round(match.predictions.FT_over_3_5) }}%</span
                    >
                  </td>
                </tr>
                <tr>
                  <td class="text-gray-400 p-2 flex-1" colspan="3">
                    Over 4.5 Goals:
                    <span class="text-black"
                      >{{ Math.round(match.predictions.FT_over_4_5) }}%</span
                    >
                  </td>
                </tr>
              </table>
            </CardOverlayItem>

          <CardOverlayItem title="Cards & Fouls" class="">
            <h3 class="text-gray-500 text-center text-xs font-bold p-1.5">
              All Competitions (Last 10 Games)
            </h3>
            <div
              class="space-y-2 mt-3"
              v-if="
                popOverData.home_team.cards &&
                popOverData.home_team.cards.length > 0
              "
              :class="{ 'only-5-toggle': cardsHomeShort }"
            >
              <div class="flex">
                <h3 class="w-1/2 uppercase text-xs text-gray-500 font-bold">
                  {{ popOverData.home_team.name }}
                </h3>
                <div class="flex-1 text-right">
                  <svg
                    class="inline-block"
                    width="8"
                    height="11"
                    viewBox="0 0 8 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 1C0 0.447715 0.447715 0 1 0L7 0C7.55228 0 8 0.447715 8 1V10C8 10.5523 7.55228 11 7 11H1C0.447715 11 0 10.5523 0 10L0 1Z"
                      fill="#FFEC3E"
                    />
                  </svg>
                </div>
                <div class="flex-1 text-right">
                  <svg
                    class="inline-block"
                    width="8"
                    height="11"
                    viewBox="0 0 8 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-3.8147e-06 1C-3.8147e-06 0.447715 0.447711 0 0.999996 0L7 0C7.55228 0 8 0.447715 8 1V10C8 10.5523 7.55228 11 7 11H0.999996C0.447711 11 -3.8147e-06 10.5523 -3.8147e-06 10L-3.8147e-06 1Z"
                      fill="#CF1818"
                    />
                  </svg>
                </div>
                <div class="flex-1 text-right">
                  <img class="w-3 inline-block" src="@/assets/icons/foul.png" />
                </div>
              </div>
              <div
                class="text-xxs only-5-item"
                v-for="(item, key) in popOverData.home_team.cards"
                :key="key"
              >
                <p
                  class="flex"
                  v-if="
                    'injured_players' in popOverData.home_team &&
                    'suspended_players' in popOverData.home_team &&
                    popOverData.home_team.injured_players.includes(item.name) ||
                    popOverData.home_team.suspended_players.includes(item.name)
                  "
                >
                  <span class="w-1/2 text-red-500"
                    >{{ item.name }}
                    <img
                      v-if="
                        popOverData.home_team.injured_players.includes(
                          item.name
                        )
                      "
                      class="w-3 inline-block relative left-[2px]"
                      src="@/assets/icons/red-cross.png"
                      title="Injured"
                    />
                    <img
                      v-if="
                        popOverData.home_team.suspended_players.includes(
                          item.name
                        )
                      "
                      class="w-3 inline-block relative left-[2px]"
                      src="@/assets/icons/yellowred.png"
                      title="Suspended"
                    />
                  </span>
                  <span class="flex-1 text-right text-red-500">{{
                    item.avg_minutes
                  }}</span>
                  <span class="flex-1 text-right text-red-500">{{
                    item.yellow_cards_all_comps
                  }}</span>
                  <span class="flex-1 text-right text-red-500">-</span>
                  <span class="flex-1 text-right text-red-500">{{
                    item.red_cards_all_comps
                  }}</span>
                  <span class="flex-1 text-right text-red-500">{{
                    item.avg_fouls
                  }}</span>
                </p>
                <p class="flex" v-else>
                  <span class="w-1/2 text-gray-400">{{ item.name }}</span>
                  <span class="flex-1 text-right">{{ item.avg_minutes }}</span>
                  <span class="flex-1 text-right">{{
                    item.yellow_cards_all_comps
                  }}</span>
                  <span class="flex-1 text-right" v-if="item.card_prediction"
                    >{{ item.card_prediction }}
                  </span>
                  <span class="flex-1 text-right" v-else>-</span>
                  <span class="flex-1 text-right">{{
                    item.red_cards_all_comps
                  }}</span>
                  <span class="flex-1 text-right">{{ item.avg_fouls }}</span>
                </p>
              </div>
              <div class="flex items-center justify-center mt-4">
                <button
                  v-on:click="toggleOnly5('cardsHomeShort')"
                  class="text-gray-400 underline text-xxs"
                >
                  Show <template v-if="cardsHomeShort">more</template
                  ><template v-else>less</template>
                </button>
              </div>
            </div>
            <div v-else class="flex items-start align-top space-x-4">
              <span
                class="w-full align-bottom py-2 text-center text-gray-500 text-xs"
              >
                No recent home team cards
              </span>
            </div>

            <div
              class="space-y-2 mt-10"
              v-if="
                popOverData.away_team.cards &&
                popOverData.away_team.cards.length > 0
              "
              :class="{ 'only-5-toggle': cardsAwayShort }"
            >
              <div class="flex">
                <h3 class="w-1/2 uppercase text-xs text-gray-500 font-bold">
                  {{ popOverData.away_team.name }}
                </h3>
                <div class="flex-1 text-right">
                  <svg
                    class="inline-block"
                    width="8"
                    height="11"
                    viewBox="0 0 8 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 1C0 0.447715 0.447715 0 1 0L7 0C7.55228 0 8 0.447715 8 1V10C8 10.5523 7.55228 11 7 11H1C0.447715 11 0 10.5523 0 10L0 1Z"
                      fill="#FFEC3E"
                    />
                  </svg>
                </div>
                <div class="flex-1 text-right">
                  <svg
                    class="inline-block"
                    width="8"
                    height="11"
                    viewBox="0 0 8 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-3.8147e-06 1C-3.8147e-06 0.447715 0.447711 0 0.999996 0L7 0C7.55228 0 8 0.447715 8 1V10C8 10.5523 7.55228 11 7 11H0.999996C0.447711 11 -3.8147e-06 10.5523 -3.8147e-06 10L-3.8147e-06 1Z"
                      fill="#CF1818"
                    />
                  </svg>
                </div>
                <div class="flex-1 text-right">
                  <img class="w-3 inline-block" src="@/assets/icons/foul.png" />
                </div>
              </div>
              <div
                class="text-xxs only-5-item"
                v-for="(item, key) in popOverData.away_team.cards"
                :key="key"
              >
                <p
                  class="flex"
                  v-if="
                    'injured_players' in popOverData.away_team &&
                    'suspended_players' in popOverData.away_team &&
                    popOverData.away_team.injured_players.includes(item.name) ||
                    popOverData.away_team.suspended_players.includes(item.name)
                  "
                >
                  <span class="w-1/2 text-red-500"
                    >{{ item.name }}
                    <img
                      v-if="
                        popOverData.away_team.injured_players.includes(
                          item.name
                        )
                      "
                      class="w-3 inline-block relative left-[2px]"
                      src="@/assets/icons/red-cross.png"
                      title="Injured"
                    />
                    <img
                      v-if="
                        popOverData.away_team.suspended_players.includes(
                          item.name
                        )
                      "
                      class="w-3 inline-block relative left-[2px]"
                      src="@/assets/icons/yellowred.png"
                      title="Suspended"
                    />
                  </span>
                  <span class="flex-1 text-right text-red-500">{{
                    item.avg_minutes
                  }}</span>
                  <span class="flex-1 text-right text-red-500">{{
                    item.yellow_cards_all_comps
                  }}</span>
                  <span class="flex-1 text-right text-red-500">-</span>
                  <span class="flex-1 text-right text-red-500">{{
                    item.red_cards_all_comps
                  }}</span>
                  <span class="flex-1 text-right text-red-500">{{
                    item.avg_fouls
                  }}</span>
                </p>
                <p class="flex" v-else>
                  <span class="w-1/2 text-gray-400">{{ item.name }}</span>
                  <span class="flex-1 text-right">{{ item.avg_minutes }}</span>
                  <span class="flex-1 text-right">{{
                    item.yellow_cards_all_comps
                  }}</span>
                  <span class="flex-1 text-right" v-if="item.card_prediction"
                    >{{ item.card_prediction }}
                  </span>
                  <span class="flex-1 text-right" v-else>-</span>
                  <span class="flex-1 text-right">{{
                    item.red_cards_all_comps
                  }}</span>
                  <span class="flex-1 text-right">{{ item.avg_fouls }}</span>
                </p>
              </div>
              <div class="flex items-center justify-center mt-4">
                <button
                  v-on:click="toggleOnly5('cardsAwayShort')"
                  class="text-gray-400 underline text-xxs"
                >
                  Show <template v-if="cardsAwayShort">more</template
                  ><template v-else>less</template>
                </button>
              </div>
            </div>
            <div v-else class="flex items-start align-top space-x-4">
              <span
                class="w-full align-bottom py-2 text-center text-gray-500 text-xs"
              >
                No recent away team cards
              </span>
            </div>
          </CardOverlayItem>

          <div
            v-if="this.displayOdds"
            class="bottom p-2 bg-gray-200 -ml-4 -mr-4"
          >
            <div class="flex text-sm">
              <p class="text-left w-1/4 overflow-hidden">best_odds</p>

              <div class="flex w-2/4 items-center justify-center flex-shrink-0">
                <span
                  v-on:click="toggleItem('home_team')"
                  class="relative flex-1 text-center group underline"
                  :class="{
                    ' text-green-400': compareOdds(
                      decimalToFraction(match.home_team.best_odds),
                      decimalToFraction(match.home_team.fair_odds)
                    ),
                  }"
                >
                  {{ convertOdds(match.home_team.best_odds) }}
                  <div
                    :class="{ '!block': itemShowing['home_team'] }"
                    class="absolute left-1/2 p-2 border text-xs w-48 z-20 bottom-full transform text-black -translate-x-1/2 bg-white hidden group-hover:block"
                  >
                    <span
                      v-for="(key, itemKey) in match.home_team.odds"
                      :key="`${itemKey}+home`"
                    >
                      <span v-if="key.bookie == match.home_team.bookmaker">
                        <span
                          v-if="
                            compareOdds(
                              decimalToFraction(key.odds),
                              decimalToFraction(match.home_team.fair_odds)
                            )
                          "
                          class="text-green-400 font-bold text-sm"
                        >
                          {{ key.bookie }} {{ convertOdds(key.odds) }}
                        </span>
                        <span v-else class="font-bold text-sm">
                          {{ key.bookie }} {{ convertOdds(key.odds) }}
                        </span>
                      </span>
                      <span v-else>
                        <span
                          v-if="
                            compareOdds(
                              decimalToFraction(key.odds),
                              decimalToFraction(match.home_team.fair_odds)
                            )
                          "
                          class="text-green-400"
                        >
                          {{ key.bookie }} {{ convertOdds(key.odds) }}
                        </span>
                        <span v-else>
                          {{ key.bookie }} {{ convertOdds(key.odds) }}
                        </span>
                      </span>
                      <br />
                    </span>
                  </div>
                </span>
                <span
                  v-on:click="toggleItem('draw')"
                  class="relative flex-1 text-center group underline"
                  :class="{
                    ' text-green-400': compareOdds(
                      decimalToFraction(match.draw.best_odds),
                      decimalToFraction(match.draw.fair_odds)
                    ),
                  }"
                >
                  {{ convertOdds(match.draw.best_odds) }}
                  <div
                    :class="{ '!block': itemShowing['draw'] }"
                    class="absolute left-1/2 p-2 border text-xs w-48 z-20 bottom-full transform text-black -translate-x-1/2 bg-white hidden group-hover:block"
                  >
                    <span
                      v-for="(key, itemKey) in match.draw.odds"
                      :key="`${itemKey}+draw`"
                    >
                      <span v-if="key.bookie == match.draw.bookmaker">
                        <span
                          v-if="
                            compareOdds(
                              decimalToFraction(key.odds),
                              decimalToFraction(match.draw.fair_odds)
                            )
                          "
                          class="text-green-400 font-bold text-sm"
                        >
                          {{ key.bookie }} {{ convertOdds(key.odds) }}
                        </span>
                        <span v-else class="font-bold text-sm">
                          {{ key.bookie }} {{ convertOdds(key.odds) }}
                        </span>
                      </span>
                      <span v-else>
                        <span
                          v-if="
                            compareOdds(
                              decimalToFraction(key.odds),
                              decimalToFraction(match.draw.fair_odds)
                            )
                          "
                          class="text-green-400"
                        >
                          {{ key.bookie }} {{ convertOdds(key.odds) }}
                        </span>
                        <span v-else>
                          {{ key.bookie }} {{ convertOdds(key.odds) }}
                        </span>
                      </span>
                      <br />
                    </span>
                  </div>
                </span>
                <span
                  v-on:click="toggleItem('away_team')"
                  class="relative flex-1 text-center group underline"
                  :class="{
                    ' text-green-400': compareOdds(
                      decimalToFraction(match.away_team.best_odds),
                      decimalToFraction(match.away_team.fair_odds)
                    ),
                  }"
                >
                  {{ convertOdds(match.away_team.best_odds) }}
                  <div
                    :class="{ '!block': itemShowing['away_team'] }"
                    class="absolute left-1/2 p-2 border text-xs w-48 z-20 bottom-full transform text-black -translate-x-1/2 bg-white hidden group-hover:block"
                  >
                    <span
                      v-for="(key, itemKey) in match.away_team.odds"
                      :key="`${itemKey}+away`"
                    >
                      <span v-if="key.bookie == match.away_team.bookmaker">
                        <span
                          v-if="
                            compareOdds(
                              decimalToFraction(key.odds),
                              decimalToFraction(match.away_team.fair_odds)
                            )
                          "
                          class="text-green-400 font-bold text-sm"
                        >
                          {{ key.bookie }} {{ convertOdds(key.odds) }}
                        </span>
                        <span v-else class="font-bold text-sm">
                          {{ key.bookie }} {{ convertOdds(key.odds) }}
                        </span>
                      </span>
                      <span v-else>
                        <span
                          v-if="
                            compareOdds(
                              decimalToFraction(key.odds),
                              decimalToFraction(match.away_team.fair_odds)
                            )
                          "
                          class="text-green-400"
                        >
                          {{ key.bookie }} {{ convertOdds(key.odds) }}
                        </span>
                        <span v-else>
                          {{ key.bookie }} {{ convertOdds(key.odds) }}
                        </span>
                      </span>
                      <br />
                    </span>
                  </div>
                </span>
              </div>
              <div class="w-1/4 text-center relative">
                <div class="group inline-block" v-on:click="toggleItem('tv')">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 mx-auto pointer-events-none"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    :class="{ 'opacity-20': !match.tvstations.length }"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3 5a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-2 2h-2.22l.123.489.804.804A1 1 0 0113 18H7a1 1 0 01-.707-1.707l.804-.804L7.22 15H5a2 2 0 01-2-2V5zm5.771 7H5V5h10v7H8.771z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <ul
                    v-if="match.tvstations.length"
                    class="absolute left-1/2 p-2 list-inside list-disc border text-left text-xs w-48 z-20 bottom-full transform -translate-x-1/2 bg-white hidden group-hover:block"
                  >
                    <li
                      v-for="(station, key) in match.tvstations"
                      :key="`${key}+tvstations`"
                    >
                      {{ station }}
                    </li>
                  </ul>
                  <ul
                    v-else
                    class="absolute left-1/2 p-2 list-inside list-disc border text-left text-xs w-48 z-20 bottom-full transform -translate-x-1/2 bg-white hidden group-hover:block"
                  >
                    <li>Not televised.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="flex text-sm">
              <p class="text-left w-1/4 overflow-hidden">fair_odds</p>

              <div class="flex w-2/4 items-center justify-center flex-shrink-0">
                <p class="flex-1 text-center">
                  {{ convertOdds(match.home_team.fair_odds) }}
                </p>
                <p class="flex-1 text-center">
                  {{ convertOdds(match.draw.fair_odds) }}
                </p>
                <p class="flex-1 text-center">
                  {{ convertOdds(match.away_team.fair_odds) }}
                </p>
              </div>
              <div class="w-1/4 text-center relative">
                <div class="group inline-block">
                  <svg
                    v-on:click="toggleItem('info')"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mx-auto h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <ul
                    :class="{ '!block': itemShowing['info'] }"
                    class="absolute left-1/2 p-2 list-inside list-disc border text-left text-xs w-48 z-20 bottom-full transform -translate-x-1/2 bg-white hidden group-hover:block"
                    v-if="match.predictions"
                  >
                    <li>
                      {{ niceName("btts") }}:
                      {{ Math.round(match.predictions.btts) }}%
                    </li>
                    <li>
                      {{ niceName("HT_clean_sheet") }}:
                      {{ Math.round(match.predictions.HT_clean_sheet) }}%
                    </li>
                    <li>
                      {{ niceName("HT_over_0_5") }}:
                      {{ Math.round(match.predictions.HT_over_0_5) }}%
                    </li>
                    <li>
                      {{ niceName("HT_over_1_5") }}:
                      {{ Math.round(match.predictions.HT_over_1_5) }}%
                    </li>
                    <li>
                      {{ niceName("FT_over_2_5") }}:
                      {{ Math.round(match.predictions.FT_over_2_5) }}%
                    </li>
                    <li>
                      {{ niceName("FT_over_3_5") }}:
                      {{ Math.round(match.predictions.FT_over_3_5) }}%
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div v-else class="flex items-center justify-center py-10">
          <Loader />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import ClickOutside from "vue-click-outside";
import Loader from "./../components/Loader";
import CardOverlayItem from "./../components/CardOverlayItem";
import MatchEventListCard from "./../components/MatchEventListCard.vue";

import API from "./../api";
import _ from "lodash";

import { Radar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
} from "chart.js";
import { set } from "lodash";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement
);

export default {
  name: "Card",
  props: [
    "mode",
    "match",
    "showOdds",
    "index",
    "gameCount",
    "competition",
    "botd",
    "oddsType",
    "extraBlurContent",
  ],
  components: {
    Loader,
    CardOverlayItem,
    Radar,
    MatchEventListCard,
  },
  data: function () {
    return {
      sliceList: {
        head_to_head: 5,
        home_team: 5,
        away_team: 5,
        home_team_cup: 5,
        away_team_cup: 5,
      },
      showHowCalcuationModal: false,
      allowedCompetitions: [
        "Premier League",
        "La Liga",
        "Bundesliga",
        "Serie A",
        "Ligue 1",
        "Eredivisie",
        "Primeira Liga",
        "Championship",
        "Brasileirão",
        "Major League Soccer",
        "Champions League",
        "Europa League",
        "Europe Conference League",
        "FA Cup",
        "Carabao Cup",
      ],
      gotOverlayData: false,
      gotHomeProfile: false,
      gotAwayProfile: false,
      itemShowing: {
        tv: false,
        info: false,
        away_team: false,
        draw: false,
        home_team: false,
      },
      popoverShowing: false,
      popOverData: {},
      profileData: {},
      toggleTimeFrameDropdowns: {"teamstats": false, "goal_scorers": false, "cards_fouls": false},
      timeFrameDropdowns: {"teamstats": "season", "goal_scorers": "season", "cards_fouls": "season"},
      goalScorersHomeShort: true,
      goalScorersAwayShort: true,
      cardsHomeShort: true,
      cardsAwayShort: true,
      teamNewsShort: true,
      displayOdds: true,
    };
  },
  // do not forget this section
  directives: {
    ClickOutside,
  },
  mounted() {
    if (!this.match) {
      return;
    }
    console.log(this.showOdds);
    if (
      (this.showOdds && this.showOddds == "false") ||
      this.showOdds == false
    ) {
      this.displayOdds = false;
    }
    if (this.extraBlurContent) {
      this.getOverlayContent(this.match);
    }
  },
  computed: {
    isShowOddsAllowed() {
      return false;
    },
    isBetPromoAllowed() {
      return this.$store.getters.isBetPromoAllowed;
    },
    chartOptions() {
      return {
        elements: {
          line: {
            borderWidth: 3,
          },
        },
      };
    },
    chartData() {
      const homeData = this.profileData.home
        ? [
            this.profileData.home.percentiles.attack,
            this.profileData.home.percentiles.possession,
            this.profileData.home.percentiles.long_ball,
            this.profileData.home.percentiles.finishing,
            this.profileData.home.percentiles.set_peices_for,
            this.profileData.home.percentiles.set_peices_against,
            this.profileData.home.percentiles.defence,
            this.profileData.home.percentiles.pressing,
          ]
        : [];
      const awayData = this.profileData.away
        ? [
            this.profileData.away.percentiles.attack,
            this.profileData.away.percentiles.possession,
            this.profileData.away.percentiles.long_ball,
            this.profileData.away.percentiles.finishing,
            this.profileData.away.percentiles.set_peices_for,
            this.profileData.away.percentiles.set_peices_against,
            this.profileData.away.percentiles.defence,
            this.profileData.away.percentiles.pressing,
          ]
        : [];

      const datasets = [];
      if (this.profileData.home) {
        datasets.push({
          label: this.profileData.home.team_name,
          data: homeData,
          backgroundColor: "rgba(229, 156, 152, 0.7)",
          borderColor: "rgba(229, 156, 152, 1)",
          pointBackgroundColor: "rgba(229, 156, 152, 0.7)",
        });
      }
      if (this.profileData.away) {
        datasets.push({
          label: this.profileData.away.team_name,
          data: awayData,
          backgroundColor: "rgba(189, 189, 251, 0.7)",
          borderColor: "rgba(189, 189, 251, 1)",
          pointBackgroundColor: "rgba(189, 189, 251, 0.7)",
        });
      }

      return {
        labels: ["ATT", "POSS", "LONG", "FINISH", "SPF", "SPA", "DEF", "PRESS"],
        datasets: datasets,
      };
    },
    title() {
      return this.mode == "chrono" || this.mode == "today"
        ? this.match.competition
        : this.match.time;
    },
    statsTimeframe(){
      return this.timeframe ? this.timeframe : "season";
    },
    groupedByPositionAway() {
      if (this.popOverData.away_team) {
        const groupedPlayers = _.groupBy(
          this.popOverData.away_team.lineup,
          "position"
        );
        return groupedPlayers;
      } else {
        return {};
      }
    },
    groupedByPositionHome() {
      if (this.popOverData.home_team) {
        const groupedPlayers = _.groupBy(
          this.popOverData.home_team.lineup,
          "position"
        );
        return groupedPlayers;
      } else {
        return {};
      }
    },
  },
  methods: {
    sliceItemLength(type, subtype) {
      if (subtype) {
        return this.popOverData[type][subtype].slice(0, this.sliceList[type]);
      } else {
        return this.popOverData[type].slice(0, this.sliceList[type]);
      }
    },
    toggleSliceItem(type) {
      if (this.sliceList[type] == 15) {
        this.sliceList[type] = 5;
      } else {
        this.sliceList[type] = this.sliceList[type] + 5;
      }
    },
    generateBackgroundBar(payload, compareTo) {
      return {
        width: payload.value ? payload.value + "%" : 0,
        backgroundColor:
          payload.value && compareTo.value && payload.value == compareTo.value
            ? "gray"
            : payload.colour == "green"
            ? "#83B76D"
            : "#D56A48",
      };
    },
    toggleTimeFrameDropdown(button) {
      this.toggleTimeFrameDropdowns[button] = !this.toggleTimeFrameDropdowns[button];
      return !this.toggleTimeFrameDropdowns[button];
    },
    getTimeFrameDropdown(type) {
      return this.timeFrameDropdowns[type];
    },
    setTimeFrameDropdown(type, timeframe) {
      this.timeFrameDropdowns[type] = timeframe;
      this.toggleTimeFrameDropdowns[type] = !this.toggleTimeFrameDropdowns[type];
      return this.timeFrameDropdowns[type];
    },
    getLastName(payload) {
      const index = payload.indexOf(" ");
      const result = payload.substring(index + 1);
      return result;
    },
    toggleOnly5(payload) {
      this[payload] = !this[payload];
    },
    colorOfMatch(payload, key) {
      if (payload[key] == "L") {
        return "bg-red-100";
      }
      if (payload[key] == "D") {
        return "bg-gray-100";
      }
      if (payload[key] == "W") {
        return "bg-green-100";
      }
    },
    recentForm(payload) {
      try {
        return this.popOverData[payload].recent_form.split("");
      } catch (err) {
        return [];
      }
    },
    recentHomeForm(payload) {
      try {
        return this.popOverData[payload].stats["season"].home_form.split("");
      } catch (err) {
        return [];
      }
    },
    recentAwayForm(payload) {
      try {
        return this.popOverData[payload].stats["season"].away_form.split("");
      } catch (err) {
        return [];
      }
    },
    recentCupForm(payload) {
      try {
        return this.popOverData[payload].recent_cup_form.split("");
      } catch (err) {
        return [];
      }
    },
    aiInsights(payload){
      try {
        return this.popOverData[payload].insights;
      } catch (err) {
        return [];
      }
    },
    addNth(n) {
      let ordinal = require("ordinal");
      return ordinal(+n);
    },
    async getOverlayContent() {
      let url = `/match`;
      if (this.botd) {
        url += `?matchKey=${this.match.match_key}&dateKey=${this.match.key}`;
      } else {
        url += `?matchKey=${this.match.key}`;
      }
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      if (urlParams.get("mode") === "test") {
        url = "/test";
      }
      const response = await API.get(url);
      let responseData = response.data;
      this.popOverData = responseData;

      if (this.allowedCompetitions.includes(this.popOverData.competition)) {
        this.getProfileContent();
      }

      this.gotOverlayData = true;
    },
    async getProfileContent() {
      console.log("Trying to get profile content");
      try {
        // const homeResponse = await API.get(
        //   `/team/profile?teamId=${this.popOverData.home_team.team_id}`
        // );
        // if (homeResponse.data.percentiles) {
        //   Vue.set(this.profileData, "home", homeResponse.data);
        // }
        this.gotHomeProfile = true;
      } catch (error) {
        this.gotHomeProfile = true;
        // console.log(error);
      }
      try {
        // const awayResponse = await API.get(
        //   `/team/profile?teamId=${this.popOverData.away_team.team_id}`
        // );
        // if (awayResponse.data.percentiles) {
        //   Vue.set(this.profileData, "away", awayResponse.data);
        // }
        this.gotAwayProfile = true;
      } catch (error) {
        this.gotAwayProfile = true;
        // console.log(error);
      }
    },
    moreInfoToggle() {
      // eslint-disable-next-line no-undef
      gtag("event", "more_info", {
        event_category: "engagement",
        event_label: "more_info",
      });
      this.popoverShowing = !this.popoverShowing;
      if (!this.popoverShowing) {
        document.getElementById("blur-wrapper").classList.remove("blur-sm");
        this.gotOverlayData = false;
      } else {
        document.getElementById("blur-wrapper").classList.add("blur-sm");
        this.getOverlayContent(this.match);
      }
    },
    hideAllOverlays() {
      this.itemShowing = {
        tv: false,
        info: this.itemShowing.info ? true : false,
        away_team: false,
        draw: false,
        home_team: false,
      };
    },
    toggleItem(payload) {
      if (payload == "tv_button") {
        // eslint-disable-next-line no-undef
        gtag("event", "tv", {
          event_category: "engagement",
          event_label: "tv_button",
        });
      } else if (["home_team", "away_team", "draw"].includes(payload)) {
        // eslint-disable-next-line no-undef
        gtag("event", "show_best_odds", {
          event_category: "engagement",
          event_label: "show_best_odds",
        });
      }
      let resetItems = {
        tv: false,
        info: false,
        away_team: false,
        draw: false,
        home_team: false,
        best_odds: false,
        fair_odds: false,
      };
      Vue.set(resetItems, payload, !this.itemShowing[payload]);
      this.itemShowing = resetItems;
    },
    niceName(key) {
      let returnResponse = false;
      if (key == "btts") {
        returnResponse = "BTTS";
      }
      if (key == "FT_over_2_5") {
        returnResponse = "Match Over 2.5";
      }
      if (key == "FT_over_3_5") {
        returnResponse = "Match Over 3.5";
      }
      if (key == "HT_clean_sheet") {
        returnResponse = "Home Clean Sheet";
      }
      if (key == "HT_over_0_5") {
        returnResponse = "Home Over 0.5";
      }
      if (key == "HT_over_1_5") {
        returnResponse = "Home Over 1.5";
      }
      if (key == "AT_clean_sheet") {
        returnResponse = "Away Clean Sheet";
      }
      if (key == "AT_over_0_5") {
        returnResponse = "Away Over 0.5";
      }
      if (key == "AT_over_1_5") {
        returnResponse = "Away Over 1.5";
      }
      return returnResponse;
    },
    toScoreFirstHalf(match, info, team) {
      var firstHalfGoals;
      if (team == "home_team") {
        firstHalfGoals =
          info.home_team.stats.goals.F.first_half /
          info.home_team.stats.goals.F.total;
        return Math.round(match.predictions.HT_over_0_5 * firstHalfGoals);
      } else if (team == "away_team") {
        firstHalfGoals =
          info.away_team.stats.goals.F.first_half /
          info.away_team.stats.goals.F.total;
        return Math.round(match.predictions.AT_over_0_5 * firstHalfGoals);
      } else {
        firstHalfGoals =
          (info.home_team.stats.goals.F.first_half /
            info.home_team.stats.goals.F.total) *
          (info.away_team.stats.goals.F.first_half /
            info.away_team.stats.goals.F.total);
        return Math.round(
          (info.home_team.stats.goals.F.first_half /
            info.home_team.stats.goals.F.total +
            info.away_team.stats.goals.F.first_half /
              info.away_team.stats.goals.F.total -
            firstHalfGoals) *
            100
        );
      }
    },
    setStatsTimeframe(timeframe) {
      this.timeframe = timeframe;
    },  
    highestPrediction(payload) {
      return (
        payload ==
        Math.max(
          this.match.home_team.prediction,
          this.match.draw.prediction,
          this.match.away_team.prediction
        )
      );
    },
    highestBestOdd(payload) {
      return (
        payload ==
        Math.max(
          this.match.home_team.best_odds,
          this.match.draw.best_odds,
          this.match.away_team.best_odds
        )
      );
    },

    gcd(a, b) {
      if (b < 0.01) return a;
      return this.gcd(b, Math.floor(a % b));
    },
    convertOdds(decimal) {
      if (this.oddsType == "decimal") {
        // return parseFloat(parseFloat(decimal) - parseFloat(1)).toFixed(2);
        return parseFloat(parseFloat(decimal)).toFixed(2);
      } else {
        var fraction = Math.round((decimal - 1) * 10) / 10;
        var len = fraction.toString().length - 1;
        var denominator = Math.pow(10, len);
        var numerator = Math.round((fraction * denominator * 10) / 10);
        var divisor = this.gcd(numerator, denominator);
        numerator /= divisor;
        denominator /= divisor;
        if (Math.floor(numerator) == -1 || Math.floor(denominator) == -1) {
          return "N/A";
        } else {
          return Math.floor(numerator) + "/" + Math.floor(denominator);
        }
      }
    },
    decimalToFraction(decimal) {
      if (this.oddsType == "decimal") {
        return decimal;
      } else {
        var fraction = Math.round((decimal - 1) * 10) / 10;
        var len = fraction.toString().length - 1;
        var denominator = Math.pow(10, len);
        var numerator = Math.round((fraction * denominator * 10) / 10);
        var divisor = this.gcd(numerator, denominator);
        numerator /= divisor;
        denominator /= divisor;
        return [Math.floor(numerator), Math.floor(denominator)];
      }
    },
    compareOdds(odds, fairOdds) {
      if (this.oddsType == "decimal") {
        return odds * 100 > fairOdds * 100; //remove decimal places as this complicates things
      } else {
        // Declare nume1 and nume2 for get the value
        // of first numerator and second numerator
        var a = odds[0];
        var b = odds[1];
        var c = fairOdds[0];
        var d = fairOdds[1];

        // Compute ad-bc
        var Y = a * d - b * c;

        return Y > 0 ? true : false;
      }
    },
    getStage(match, data) {
      if (match.stage == "Regular Season") {
        if ("round" in data) {
          return "Gameweek " + match.round;
        } else {
          return (
            "Gameweek " +
            (Number(data.home_team.stats.matches_played) + Number(1))
          );
        }
      } else {
        return match.stage;
      }
    },
  },
};
</script>

<style scoped>
.only-5-toggle .only-5-item {
  display: none;
}

.only-5-toggle .only-5-item:nth-child(-n + 6) {
  display: block;
}
</style>
